import { TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { Link, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function CreateSchools() {
    const {encrypteSID} = useParams();
    const [SID, setSID] = useState('');
    const [scName, setScName] = useState('');
    const [aLine1, setALine1] = useState('');
    const [aLine2, setALine2] = useState('');
    const [stateName, setStateName] = useState('');
    const [stateNameId, setStateNameId] = useState('');
    const [stateNameOptions, setStateNameOptions] = useState([]);
    const [cityName, setCityName] = useState('');
    const [cityNameId, setCityNameId] = useState('');
    const [cityNameOptions, setCityNameOptions] = useState([]);
    const [mNo, setMNo] = useState('');
    const [mail, setMail] = useState('');
    const [website, setWebsite] = useState('');
    const [schoolCode, setSchoolCode] = useState('');
    const [schoolBoard, setSchoolBoard] = useState('');
    const [affiliationNo, setAffiliationNo] = useState('');
    const [affiliationDate, setAffiliationDate] = useState('');
    const [principalName, setPrincipalName] = useState('');
    const [directorName, setDirectorName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [logoSizeExceeds, setLogoSizeExceeds] = useState(null);
    const [errors, setErrors] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const { userLoginType, setUserLoginType } = useSession();

    useEffect(() => {
        var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (loginType) {
            setUserLoginType(loginType);
        }
    }, [setUserLoginType]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteSID) {
            const decryptedId = decryptId(encrypteSID, secretKey);
            setSID(decryptedId);
        }
    }, [encrypteSID]);

    const handleScNameChange = (e) => {
        const value = e.target.value
        setScName(value);
        setErrors((prevError) => ({
            ...prevError,
            scName: value ? '' : 'Please Enter Organisation Name'
        }))
    };

    const handleALine1Change = (e) => {
        const value = e.target.value
        setALine1(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            aLine1: value ? '' : 'Please Enter Address'
        }))
    };

    const handleALine2Change = (e) => {
        setALine2(e.target.value);
    };

    const handleStateNameChange = (e) => {
        const value = e.target.value
        setStateName(value);
        const selctedState = stateNameOptions.find(option => option.name === value)
        if (selctedState) {
            setStateNameId(selctedState.id)
        }
    }

    const handleCityNameChange = (e) => {
        const value = e.target.value
        setCityName(value);
        const selctedCity = cityNameOptions.find(option => option.name === value)
        if (selctedCity) {
            setCityNameId(selctedCity.id)
        }
    }

    const handleMNoChange = (e) => {
        const value = e.target.value
        setMNo(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            mNo: value ? '' : 'Please Enter Mobile No'
        }))
    };

    const handleMailChange = (e) => {
        const value = e.target.value
        setMail(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            mail: value ? '' : 'Please Enter Email Address'
        }))
    };

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    };

    const handleSchoolCodeChange = (e) => {
        setSchoolCode(e.target.value);
    };

    const handleSchoolBoardChange = (e) => {
        const value = e.target.value
        setSchoolBoard(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            schoolBoard: value ? '' : 'Please Enter School Board'
        }))
    };

    const handleAffiliationNoChange = (e) => {
        setAffiliationNo(e.target.value);
    };

    const handleAffiliationDateChange = (e) => {
        setAffiliationDate(e.target.value);
    };

    const handlePrincipalNameChange = (e) => {
        const value = e.target.value
        setPrincipalName(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            principalName: value ? '' : 'Please Enter Principal Name'
        }))
    };

    const handleDirectorNameChange = (e) => {
        const value = e.target.value
        setDirectorName(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            directorName: value ? '' : 'Please Enter Director Name'
        }))
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value
        setUsername(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            username: value ? '' : 'Please Enter UserName'
        }))
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value
        setPassword(e.target.value);
        setErrors((prevError) => ({
            ...prevError,
            password: value ? '' : 'Please Enter Password'
        }))
    };

    const handleLogoUploadClick = () => {
        document.getElementById('LogoUpload').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 200 * 1024) {
                setLogoSizeExceeds('Logo uploaded exceeds maximum file size limit (200 KB).');
                setSelectedLogo(null);
            } else {
                setLogoSizeExceeds('');
                setSelectedLogo(file);
            }
        }
    };

    const handleRemoveFile = () => {
        setSelectedLogo(null);
        setLogoSizeExceeds('');
        const logoUploadInput = document.getElementById('LogoUpload');
        if (logoUploadInput) {
            logoUploadInput.value = '';
        }
    };

    useEffect(() => {
        const fetchState = async () => {
            try {
                const response = await fetch(`/api/getStates`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.StateName !== null).map(option => ({
                        name: option.StateName,
                        id: option.STID,
                    }));
                    setStateNameOptions(filteredOptions);
                }
            } catch (error) {
                console.error('Internal Server Error');
            }
        };

        const fetchCity = async () => {
            try {
                const response = await fetch(`/api/getCityBuState/${stateNameId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.DistName !== null).map(option => ({
                        name: option.DistName,
                        id: option.DTID,
                    }));
                    setCityNameOptions(filteredOptions);
                }
            } catch (error) {
                console.error('Internal Server Error');
            }
        };

        fetchState();
        if (stateNameId) {
            fetchCity();
        }
    }, [stateNameId]);

    const handleReset = () => {
        setSID('');
        setScName('');
        setALine1('');
        setALine2('');
        setStateName('');
        setStateNameId('');
        setCityName('');
        setCityNameId('');
        setMNo('');
        setMail('');
        setWebsite('');
        setSchoolCode('');
        setSchoolBoard('');
        setAffiliationNo('');
        setAffiliationDate('');
        setPrincipalName('');
        setDirectorName('');
        setUsername('');
        setPassword('');
    };

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setShowModal(false);
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        if(!scName || !aLine1 || !stateNameId || !cityNameId || !mNo || !mail || !schoolBoard || !principalName || !directorName || !username || !password){
            setErrors((prevErrors) => ({
                ...prevErrors,
                scName: !scName ? 'Organisation Name Is Required' : prevErrors.scName,
                aLine1: !aLine1 ? 'Address Is Required' : prevErrors.aLine1,
                stateNameId: !stateNameId ? 'State Is Required' : prevErrors.stateNameId,
                cityNameId: !cityNameId ? 'City Is Required' : prevErrors.cityNameId,
                mNo: !mNo ? 'Mobile No Is Required' : prevErrors.mNo,
                mail: !mail ? 'Email Address Is Required' : prevErrors.mail,
                schoolBoard: !schoolBoard ? 'School Board Is Required' : prevErrors.schoolBoard,
                principalName: !principalName ? 'Principal Name Is Required' : prevErrors.principalName,
                directorName: !directorName ? 'Director Name Is Required' : prevErrors.directorName,
                username: !username ? 'Username Is Required' : prevErrors.username,
                password: !password ? 'Password Is Required' : prevErrors.password,
            }))

            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('scName', scName);
        formData.append('aLine1', aLine1);
        formData.append('aLine2', aLine2);
        formData.append('stateNameId', stateNameId);
        formData.append('cityNameId', cityNameId);
        formData.append('mNo', mNo);
        formData.append('mail', mail);
        formData.append('website', website);
        formData.append('schoolCode', schoolCode);
        formData.append('schoolBoard', schoolBoard);
        formData.append('affiliationNo', affiliationNo);
        formData.append('affiliationDate', affiliationDate);
        formData.append('principalName', principalName);
        formData.append('directorName', directorName);
        formData.append('username', username);
        formData.append('password', password);
        formData.append('selectedLogo', selectedLogo);
        formData.append('userLoginType', userLoginType);
        if (SID) {
            try {
                const response = await fetch(`/api/UpdateSchool/${SID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ scName, aLine1, aLine2, stateNameId, cityNameId, mNo, mail, website, schoolCode, schoolBoard, affiliationNo, affiliationDate, principalName, directorName, username, password, userLoginType }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 2000);
                    handleReset();
                } else {
                    const data = await response.json();
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 2000);
                }
            } catch (error) {
                console.error('Error uploading', error);
            } finally{
                setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/CreateSchoolMaster`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 2000);
                    handleReset();
                } else {
                    const data = await response.json();
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 2000);
                }
            } catch (error) {
                console.error('Error uploading', error);
            } finally {
                setLoading (false);
            }
        }
        setIsSubmitting(false);
    }

    useEffect(() => {
        if (SID) {
            fetch(`/api/updateSchoolDetails/${SID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const data = result[0];
                        setScName(data.SchoolName || '')
                        setALine1(data.Add1 || '')
                        setALine2(data.Add2 || '')
                        setStateName(data.StateName || '');
                        setStateNameId(data.STID || '');
                        setCityName(data.DistName || '')
                        setCityNameId(data.DTID || '')
                        setMNo(data.Mobile || '');
                        setMail(data.Email || '');
                        setWebsite(data.Web || '');
                        setSchoolCode(data.SchoolCode || '');
                        setSchoolBoard(data.SchoolBoard || '');
                        setAffiliationNo(data.AffiliationNo || '');
                        setAffiliationDate(data.AffiliationDate.slice(0, 10) || '');
                        setPrincipalName(data.PrincipalName || '');
                        setDirectorName(data.DirectorName || '');
                        setUsername(data.Username || '');
                        setPassword(data.Password || '');
                    } 
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [SID]);

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part, index) => {
            const isNumeric = /^\d+$/.test(part);
            const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
            const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);

            if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
                urlSoFar += `/${part}`;
                breadcrumbsArray.push({ label: part, url: urlSoFar });
            }
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Success Message</h5>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-2 mx-2 mobile-margin'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
                <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                    <div className='row'>
                        <div className='col-md-4 mb-2'>
                            <TextField label="School Name" variant="standard" fullWidth value={scName} onChange={handleScNameChange} helperText={errors.scName} error={!!errors.scName} />
                        </div>
                        <div className='col-md-8 mb-2'>
                            <TextField label="Add Line 1" variant="standard" fullWidth value={aLine1} onChange={handleALine1Change} helperText={errors.aLine1} error={!!errors.aLine1} />
                        </div>
                        <div className='col-md-8 mb-2'>
                            <TextField label="Add Line 2" variant="standard" fullWidth value={aLine2} onChange={handleALine2Change} />
                        </div>
                        <div className='col-4 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.stateNameId}>
                                <InputLabel id="demo-simple-select-standard-label">Select State</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={stateName} onChange={handleStateNameChange}>
                                    {stateNameOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.stateNameId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-4 col-md-3 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.cityNameId}>
                                <InputLabel id="demo-simple-select-standard-label">Select City</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cityName} onChange={handleCityNameChange}>
                                    {cityNameOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.cityNameId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-4 col-md-3 mb-2'>
                            <TextField label="Mobile No" variant="standard" type='number' fullWidth value={mNo} onChange={handleMNoChange} helperText={errors.mNo} error={!!errors.mNo} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Email" variant="standard" fullWidth value={mail} onChange={handleMailChange} helperText={errors.mail} error={!!errors.mail} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Website" variant="standard" fullWidth value={website} onChange={handleWebsiteChange} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="School Code" variant="standard" fullWidth value={schoolCode} onChange={handleSchoolCodeChange} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="School Board" variant="standard" fullWidth value={schoolBoard} onChange={handleSchoolBoardChange} helperText={errors.schoolBoard} error={!!errors.schoolBoard} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Affiliation No" variant="standard" fullWidth value={affiliationNo} onChange={handleAffiliationNoChange} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Affiliation Date" variant="standard" type='date' fullWidth value={affiliationDate} onChange={handleAffiliationDateChange} InputLabelProps={{ shrink: true }} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Principal Name" variant="standard" fullWidth value={principalName} onChange={handlePrincipalNameChange} helperText={errors.principalName} error={!!errors.principalName} />
                        </div>
                        <div className='col-3 mb-2'>
                            <TextField label="Director Name" variant="standard" fullWidth value={directorName} onChange={handleDirectorNameChange} helperText={errors.directorName} error={!!errors.directorName} />
                        </div>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label="Username" variant="standard" fullWidth value={username} onChange={handleUsernameChange} helperText={errors.username} error={!!errors.username} />
                        </div>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label="Password" variant="standard" fullWidth value={password} onChange={handlePasswordChange} helperText={errors.password} error={!!errors.password} />
                        </div>
                        {!SID && <div className='col-12 mb-2 d-flex'>
                            <div className='d-flex align-items-center m-auto'>
                                <div className={`d-flex border rounded-4 ${selectedLogo ? 'd-none' : ''}`} onClick={handleLogoUploadClick}>
                                    <div>
                                        <button className="me-2 rounded-4 btn btn-danger" type="button" >Upload Logo</button>
                                    </div>
                                    <div className="narrowFormats"><p className='mb-0'>DOC, DOCx, PDF, RTF | Max: 200 KB</p></div>
                                </div>
                                <input className="d-none" type="file" id="LogoUpload" accept=".doc,.docx,.pdf,.rtf" an onChange={handleFileChange} />
                                {selectedLogo && (
                                    <div className='mt-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <strong>{selectedLogo.name}</strong> ({(selectedLogo.size / 1024 / 1024).toFixed(2)} MB)
                                            </div>
                                            <button className="btn btn-outline-danger btn-sm ms-2" type="button" onClick={handleRemoveFile}>Remove</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {logoSizeExceeds && (<span className='text-danger' style={{ fontSize: '12px' }}>{logoSizeExceeds}</span>)}
                        </div>}
                    </div>
                    <div className='my-2 text-center'>
                        <Button variant='contained' color='primary' onClick={handleSubmit} disabled={logoSizeExceeds} >{SID ? 'Update' : 'Create'}</Button>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
                        <div className="modal-body text-center my-2 mx-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
