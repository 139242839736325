import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import { useSession } from './SessionContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from 'react-quill';
import CryptoJS from 'crypto-js';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function AttemptTest() {
    const { encrypteEXID } = useParams();
    const [EXID, setEXID] = useState('');
    const [data, setData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { sTRID, setSTRID, sTPhoto, setSTPhoto, studentName, setStudentName } = useSession();

    useEffect(() => {
        const sTRID = document.cookie.replace(/(?:(?:^|.*;\s*)sTRID\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (sTPhoto) {
            setSTRID(sTRID);
            setSTPhoto(sTPhoto);
            setStudentName(studentName);
        }
    }, [setSTRID, setSTPhoto, setStudentName]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteEXID) {
            const decryptedId = decryptId(encrypteEXID, secretKey);
            setEXID(decryptedId);
        }
    }, [encrypteEXID]);

    const fetchExam = useCallback(async () => {
        try {
            const response = await fetch(`/api/getExamTitleAndInstructions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ EXID }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [EXID]);

    useEffect(() => {
        if (EXID) fetchExam();
    }, [EXID, fetchExam]);

    const encryptId = (EXID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(EXID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleAttemptTest = async (EXID) => {
        setLoading(true);

        if (isSubmitting) {
            return;
        }

        if(!sTRID || !EXID ){
            setLoading(false);
            setIsSubmitting(false);
            toast.error("Something Went Wrong!")
            return;
        }
        
        setIsSubmitting(true);

        if (EXID) {
            try {
                const response = await fetch(`/api/CreateStudentResult`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ sTRID, EXID }),
                });

                if (response.ok) {
                    const encrypteEXID = encryptId(EXID, secretKey);
                    navigate(`/AttemptTestPhaseOne/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`)
                } else {
                    const data = await response.json();
                    toast.error(data.error)
                }
            } catch (error) {
                console.error('Error uploading', error);
            } finally{
                setLoading(false);
            }
        } 
        setIsSubmitting(false);
    }
       
    const handleLogout = () => {
        document.cookie = "loginType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "oId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "logo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "orgName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "sID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "schoolLogo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "schoolName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "sTRID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "sTPhoto=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "studentName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        fetch(`/Logout`, {
            method: 'POST',
        })
            .then((response) => {
                if (response.status === 200) {
                    window.localStorage.removeItem("isLoggedIn");
                    window.location.href = "/";
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error('Logout failed:', error);
            });
    };

    return (
        <>
            <nav className='navbar bg-body-tertiary shadow-sm mb-1 fixed-top'>
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <h1 className="navbar-brand mb-0 text-muted text-truncate flex-grow-1 mx-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <CloseTwoToneIcon style={{ marginRight: '8px' }} onClick={() => {navigate(`/ViewExams`)}} />
                        {data.ExamTitle}
                    </h1>

                    <div className="dropdown mx-3">
                        <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0 p-2"
                            id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"
                            style={{ border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9", minWidth: '150px' }}>
                            <img src={sTPhoto ? `/api/Students/${sTPhoto}` : "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png"}
                                alt="studentprofile" className="profile-image rounded-circle me-2" style={{ width: '32px', height: '32px' }} />
                            <span style={{ fontSize: "16px", fontWeight: "bold", color: "#333", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {studentName}
                            </span>
                        </p>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                            <li>
                                <Link className="dropdown-item" to="/StudentProfile?Type=StudentProfile" style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccountCircleTwoToneIcon style={{ marginRight: '1rem' }} />Profile
                                </Link>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center' }}>
                                    <LogoutRoundedIcon style={{ marginRight: '1rem' }} />Log out
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div style={{marginTop: '5rem'}}>
                <div className='w-75 m-auto'>
                    <h4 className='py-3 mt-3 border-bottom border-dark'>{data.ExamTitle}</h4>
                    <ReactQuill modules={{ toolbar: false }} value={data.Instructions} readOnly={true} className="no-border-quill"/>
                    <div className='w-25 m-auto text-center'>
                        <Button variant='contained' color='primary' fullWidth className='my-2' onClick={() => handleAttemptTest(EXID)}>Start</Button>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
                        <div className="modal-body text-center my-2 mx-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}


