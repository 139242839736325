import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from 'react'
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function ViewSchools() {
    const [data, setData] = useState([]);
    const [stateName, setStateName] = useState('');
    const [stateNameId, setStateNameId] = useState('');
    const [stateNameOptions, setStateNameOptions] = useState([]);
    const [cityName, setCityName] = useState('');
    const [cityNameId, setCityNameId] = useState('');
    const [cityNameOptions, setCityNameOptions] = useState([]);;
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleStateNameChange = (e) => {
        const value = e.target.value
        setStateName(value);
        const selctedState = stateNameOptions.find(option => option.name === value)
        if (selctedState) {
            setStateNameId(selctedState.id)
        }
    }

    const handleCityNameChange = (e) => {
        const value = e.target.value
        setCityName(value);
        const selctedCity = cityNameOptions.find(option => option.name === value)
        if (selctedCity) {
            setCityNameId(selctedCity.id)
        }
    }

    useEffect(() => {
        const fetchState = async () => {
            try {
                const response = await fetch(`/api/getStates`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.StateName !== null).map(option => ({
                        name: option.StateName,
                        id: option.STID,
                    }));
                    setStateNameOptions(filteredOptions);
                }
            } catch (error) {
                console.error('Internal Server Error');
            }
        };

        const fetchCity = async () => {
            try {
                const response = await fetch(`/api/getCityBuState/${stateNameId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.DistName !== null).map(option => ({
                        name: option.DistName,
                        id: option.DTID,
                    }));
                    setCityNameOptions(filteredOptions);
                }
            } catch (error) {
                console.error('Internal Server Error');
            }
        };

        fetchState();
        if (stateNameId) {
            fetchCity();
        }
    }, [stateNameId]);

    const handleReset = () => {
        setStateName('');
        setStateNameId('');
        setCityName('');
        setCityNameId('');
    };

    const fetchSchools = useCallback(async () => {
        try {
            const response = await fetch(`/api/getSchoolDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ stateNameId, cityNameId }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [stateNameId, cityNameId]);

    useEffect(() => {
        fetchSchools();
    }, [fetchSchools]);

    const encryptId = (SID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(SID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleEditClick = (SID) => {
        const encrypteSID = encryptId(SID, secretKey);
        navigate(`/CreateSchools/${encodeURIComponent(encrypteSID)}?Type=CreateSchools`)
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-4 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Select State</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={stateName} onChange={handleStateNameChange}>
                                    {stateNameOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-4 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Select City</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cityName} onChange={handleCityNameChange}>
                                    {cityNameOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-4'>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                        </div>
                    </div>
                </div>

                <div className='shadow mb-5 rounded' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr>
                                <th>School Name</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.SchoolName}</td>
                                    <td>{item.Mobile}</td>
                                    <td>{item.Email}</td>
                                    <td>{item.Username}</td>
                                    <td>{item.Password}</td>
                                    <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.SID)}><i className="bi bi-pencil-square fs-6"></i></p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
