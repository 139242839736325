import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ExamPortalLogIn from './Components/ExamPortalLogIn';
import AdminNavbar from './Components/AdminNavbar'
import AdminSidebar from './Components/AdminSidebar'
import AdminDashboard from './Components/AdminDashboard'
import CreateRegion from './Components/CreateRegion'
import CreateState from './Components/CreateState'
import CreateDistrict from './Components/CreateDistrict'
import CreateClasses from './Components/CreateClasses'
import CreateSubject from './Components/CreateSubject'
import DefineExamLevel from './Components/DefineExamLevel'
import CreateExamSet from './Components/CreateExamSet'
import CreateInstructions from './Components/CreateInstructions'
import CreateQuestionBank from './Components/CreateQuestionBank'
import ViewQuestionBank from './Components/ViewQuestionBank'
import QuestionFormat from './Components/QuestionFormat'
import CreateOnlineExam from './Components/CreateOnlineExam'
import ViewOnlineExam from './Components/ViewOnlineExam'
import StudentResultMaster from './Components/StudentResultMaster'
import StudentPerformanceDetails from './Components/StudentPerformanceDetails'
import CreateSchools from './Components/CreateSchools'
import ViewSchools from './Components/ViewSchools'
import ViewSRStudents from './Components/ViewSRStudents'
import CreateOrganisation from './Components/CreateOrganisation'
import RegisterStudents from './Components/RegisterStudents'
import ViewRegisterStudents from './Components/ViewRegisterStudents'
import StudentProfile from './Components/StudentProfile'
import ViewExams from './Components/ViewExams'
import AttemptTest from './Components/AttemptTest'
import AttemptTestPhaseOne from './Components/AttemptTestPhaseOne'
import ExamSubmission from './Components/ExamSubmission'
import StopTheServer from './Components/StopTheServer'
import './App.css';

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (process.env.NODE_ENV === 'production') {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    }, false);

    document.addEventListener('keydown', function (e) {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    });
  }

  return (

    <Routes>
      <Route path="/" element={
        <>
          <ExamPortalLogIn />
        </>
      } />
      <Route path="/Dashboard" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <AdminDashboard />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateRegion" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateRegion />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateState" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateState />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateDistrict" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateDistrict />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateClasses" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateClasses />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateSubject" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateSubject />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/DefineExamLevel" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <DefineExamLevel />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateExamSet" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateExamSet />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateInstructions" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateInstructions />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateQuestionBank/:encrypteQBID?" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateQuestionBank />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewQuestionBank" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewQuestionBank />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/QuestionFormat/:encrypteQBID?" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <QuestionFormat />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateOnlineExam/:encrypteEXID?" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateOnlineExam />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewOnlineExam" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewOnlineExam />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/StudentResultMaster" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <StudentResultMaster />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/StudentPerformanceDetails/:encrypteSTRID/:EXID" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <StudentPerformanceDetails />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateSchools/:encrypteSID?" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateSchools />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewSchools" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewSchools />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewSRStudents" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewSRStudents />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/CreateOrganisation" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <CreateOrganisation />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/RegisterStudents/:encrypteSTRID?" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <RegisterStudents />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewRegisterStudents" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewRegisterStudents />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/StudentProfile" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <StudentProfile />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ViewExams" element={
        <>
          {isLoggedIn ? (
            <div className='app-container'>
              <AdminSidebar />
              <div className="content">
                <AdminNavbar />
                <ViewExams />
              </div>
            </div>
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/AttemptTest/:encrypteEXID" element={
        <>
          {isLoggedIn ? (
              <AttemptTest />
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/AttemptTestPhaseOne/:encrypteEXID" element={
        <>
          {isLoggedIn ? (
              <AttemptTestPhaseOne />
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/ExamSubmission/:encrypteEXID" element={
        <>
          {isLoggedIn ? (
              <ExamSubmission />
          ) :
            (<ExamPortalLogIn />)}
        </>
      } />
      <Route path="/StopTheServer" element={
        <>
          <StopTheServer />
        </>
      } />
    </Routes>

  );
}
export default App;
