import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from 'react'
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function ViewRegisterStudents() {
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = `${now.getMonth() + 1}`.padStart(2, '0');
        const day = `${now.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(getCurrentDate());
    const [toDate, setToDate] = useState(getCurrentDate());
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [classOptons, setClassOptions] = useState([]);
    const [DeleteId, setDeleteId] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSClassChange = (e) => {
        const selectedValue = e.target.value
        setSClass(selectedValue)
        const selectedClasses = classOptons.find(option => option.name === selectedValue);
        if (selectedClasses) {
            setSelectedClassId(selectedClasses.id)
        }
    };

    useEffect(() => {
        const fetchClass = async () => {
            try {
                const response = await fetch(`/api/getClasses`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                        name: option.Class,
                        id: option.CLID,
                        min: option.MinAge,
                        max: option.MaxAge
                    }));
                    setClassOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchClass();
    }, []);

    const handleReset = () => {
        setSClass('');
        setSelectedClassId([]);
    };

    const fetchSubjects = useCallback(async () => {
        try {
            const response = await fetch(`/api/getFilteredRegisteredStudents`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fromDate, toDate, selectedClassId }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [fromDate, toDate, selectedClassId]);

    useEffect(() => {
        fetchSubjects();
    }, [fetchSubjects]);

    const encryptId = (STRID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(STRID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleEditClick = (STRID) => {
        const encrypteSTRID = encryptId(STRID, secretKey);
        navigate(`/RegisterStudents/${encodeURIComponent(encrypteSTRID)}?Type=RegisterStudents`)
    };

    const handleConfirmation = async (STRID) => {
        setIsDeleted(true);
        setDeleteId(STRID)
    };

    const handleCancel = async () => {
        setIsDeleted(false);
        setDeleteId('')
    };

    const handleDelete = async (STRID) => {
        try {
            const response = await fetch(`/api/DeleteStudent/${STRID}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setData((prevData) => prevData.filter(item => item.STRID !== STRID));
                const Data = await response.json();
                setIsDeleted(false)
                setShowModal(true);
                setFailureMessage('');
                setSuccessMessage(Data.message);
                DeleteId('');
            } else {
                const Data = await response.json();
                setIsDeleted(false);
                setShowModal(true);
                setSuccessMessage('');
                setFailureMessage(Data.error);
            }
        } catch (error) {
            console.error('Error deleting Class:', error);
        }
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Message</h5>
                            <span onClick={()=> setShowModal(false)}><i className="bi bi-x-lg fs-6"></i></span>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label='From Date' type='date' variant="standard" fullWidth value={fromDate} onChange={handleFromDateChange}  InputLabelProps={{shrink: true}} />
                        </div>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label='To Date' type='date' variant="standard" fullWidth value={toDate} onChange={handleToDateChange}  InputLabelProps={{shrink: true}} />
                        </div>
                        <div className='col-6 col-md-3 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Class</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                    {classOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-6 col-md-3'>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                        </div>
                    </div>
                </div>

                <div className='shadow mb-5 rounded' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap table-striped mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <th>#</th>
                                <th>Image</th>
                                <th>SRNO</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Gender</th>
                                <th>Class</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <td>{index + 1}</td>
                                    <td><img src={`/api/Students/${item.STPhoto}`} width="60" height="60" className='img-fluid rounded-circle' alt='studentImage' /></td>
                                    <td>{item.SRNo}</td>
                                    <td>{item.RegDate && item.RegDate.slice(0, 10)}</td>
                                    <td>{item.StudentName}</td>
                                    <td>{item.FName}</td>
                                    <td>{item.Gender}</td>
                                    <td>{item.Class}</td>
                                    <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.STRID)}><i className="bi bi-pencil-square fs-6"></i></p></td>
                                    <td><p className='badge badge-danger-lighten mx-2' onClick={() => handleConfirmation(item.STRID)}><i className="bi bi-trash-fill fs-6"></i></p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {isDeleted && (
                    <div className="modal-backdrop show"></div>
                )}
                <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete <strong>Student?</strong></h5>
                            </div>
                            <div className="modal-body">
                                <p>This Will Delete Student <span className='text-danger'>Permannently</span></p>
                                <div className='d-flex flex-row-reverse '>
                                    <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
                                    <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


