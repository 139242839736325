import { TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react'
import { useSession } from './SessionContext';
import { Link, useLocation } from "react-router-dom";

export default function CreateSubject() {
    const [data, setData] = useState([]);
    const [SBID, setSBID] = useState('');
    const [classData, setClassData] = useState([]);
    const [subjectName, setSubjectName] = useState('');
    const [slNo, setSlNo] = useState('');
    const [status, setStatus] = useState('');
    const statusOption = ['Active', 'Inactive']
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassID] = useState([]);
    const [classOptons, setClassOptions] = useState([]);
    const [DeleteId, setDeleteId] = useState('');
    const [errors, setErrors] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const { userLoginType, setUserLoginType } = useSession();

    useEffect(() => {
        var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (loginType) {
            setUserLoginType(loginType);
        }
    }, [setUserLoginType]);

    const handleSubjectNameChange = (e) => {
        const value = e.target.value
        setSubjectName(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            subjectName : value ? '' : 'Please Enter Subject Name',
        }))
    };

    const handleSLNoChange = (e) => {
        const value = e.target.value
        setSlNo(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            slNo : value ? '' : 'Please Enter Serial No',
        }));
    };

    const handleStatusChange = (e) => {
        const value = e.target.value
        setStatus(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            status : value ? '' : 'Please Select Status',
        }))
    };

    const handleSClassChange = (e) => {
        const selectedValue = e.target.value
        setSClass(selectedValue)
        const selectedClasses = classOptons.find(option => option.name === selectedValue);
        if (selectedClasses) {
            setSelectedClassID(selectedClasses.id)
        }
    };

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setFailureMessage('');
        setShowModal(false);
    };

    const handleCheckboxChange = (CLID, Class) => {
        const classObj = { CLID, Class };
        const index = selectedClasses.findIndex(item => item.CLID === CLID && item.Class === Class);
        if (index !== -1) {
            setSelectedClasses(selectedClasses.filter((_, i) => i !== index));
        } else {
            setSelectedClasses([...selectedClasses, classObj]);
        }
    };

    const handleReset = () => {
        setSelectedClasses([]);
        setSubjectName('');
        setSlNo('');
        setStatus('');
        setSBID('');
        setSClass('');
        setSelectedClassID([]);
    };

    useEffect(() => {
        const fetchClass = async () => {
            try {
                const response = await fetch(`/api/getClasses`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                        name: option.Class,
                        id: option.CLID,
                        min: option.MinAge,
                        max: option.MaxAge
                    }));
                    setClassOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchClass();
    }, []);

    useEffect(() => {
        fetch(`/api/getClasses`)
            .then(response => response.json())
            .then(data => {
                const dataArray = data.recordset;
                if (Array.isArray(dataArray)) {
                    setClassData(dataArray);
                } else {
                    console.error('Invalid data format from the API:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching data from the API:', error);
            });
    }, []);

    useEffect(() => {
        if (SBID) {
            fetch(`/api/getUpdateSubjects/${SBID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setSubjectName(clientdata.Subject || '');
                        setSlNo(clientdata.SNo || '');
                        setStatus(clientdata.SBStatus || '');
                    }
                })
                .catch((error) => {
                });
        }
    }, [SBID]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (isSubmitting) {
            return;
        }

        if(!subjectName || !slNo || !status){
            setErrors((prevErrors) => ({
                ...prevErrors,
                subjectName: !subjectName ? 'Subject Name Is Required' : prevErrors.subjectName,
                slNo: !slNo ? 'Serial Number Is Required' : prevErrors.slNo,
                status: !status ? 'Status Is Required' : prevErrors.status,
            }))

            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        setIsSubmitting(true);

        if (SBID) {
            try {
                const response = await fetch(`/api/UpdateSubjects/${SBID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ subjectName, slNo, status, userLoginType }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.messages);
                    handleReset();
                    fetchSubjects();
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.errors);
                }
            } catch (error) {
                console.error('Error updating department:', error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/CreateSubjects`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ subjectName, slNo, status, selectedClasses, userLoginType }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.messages);
                    handleReset();
                    fetchSubjects();
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.errors);
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        setIsSubmitting(false);
    };

    const fetchSubjects = useCallback(async () => {
        try {
            const response = await fetch(`/api/getFilteredSubjectDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selectedClassId }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [selectedClassId]);

    useEffect(() => {
        fetchSubjects();
    }, [fetchSubjects]);


    const handleEditClick = (SBID) => {
        setSBID(SBID);
    };

    const handleConfirmation = async (SBID) => {
        setIsDeleted(true);
        setDeleteId(SBID)
    };

    const handleCancel = async () => {
        setIsDeleted(false);
        setDeleteId('')
    };

    const handleDelete = async (SBID) => {
        try {
            const response = await fetch(`/api/DeleteSubjects/${SBID}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setData((prevData) => prevData.filter(item => item.SBID !== SBID));
                setFailureMessage('');
                setSuccessMessage('Subjects Deleted successfully.');
                setShowModal(true);
                setIsDeleted(false);
                setDeleteId('')
            } else {
                setShowModal(true);
                setSuccessMessage('');
                setFailureMessage('Error deleting Subjects.');
                setIsDeleted(false);
                setDeleteId('')
            }
        } catch (error) {
            console.error('Error deleting Subjects:', error);
        }
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Success Message</h5>
                            <span onClick={removeSuccessMessage}><i className="bi bi-x-lg fs-6"></i></span>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                    <div className='row'>
                        {!SBID && (
                            <div className='col-md-2 mb-2 mb-md-0'>
                                <div style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                    <div className='bg-body-tertiary' style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                                        <label className='form-label mb-0' htmlFor="title" >Select Class:</label>
                                    </div>
                                    <table className='table table-nowrap mt-0'>
                                        <tbody>
                                            {classData.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: '1%' }}><input type="checkbox" checked={selectedClasses.some(selectedItem => selectedItem.CLID === item.CLID && selectedItem.Class === item.Class)} onChange={() => handleCheckboxChange(item.CLID, item.Class)} required /></td>
                                                    <td>{item.Class}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        <div className={SBID ? 'col-md-12' : 'col-md-10'}>
                            <div className='row d-flex align-items-center'>
                                <div className='col-6 col-md-4 mb-2'>
                                    <TextField label="Subject Name" variant="standard" fullWidth value={subjectName} onChange={handleSubjectNameChange} helperText={errors.subjectName} error={!!errors.subjectName} />
                                </div>
                                <div className='col-6 col-md-4 mb-2'>
                                    <TextField label="SLNo" variant="standard" type="number" fullWidth value={slNo} onChange={handleSLNoChange} helperText={errors.slNo} error={!!errors.slNo} />
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.status}>
                                        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={status} onChange={handleStatusChange}>
                                            {statusOption.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.status}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!SBID && selectedClasses.length === 0}>{SBID ? 'Update' : 'Save'}</Button>
                                </div>
                                <div className='col-6'>
                                    <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-2 mx-0'>
                    <div className='col-md-3 px-0'>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Select Class For Data Filtering</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                {classOptons.map((option, index) => (
                                    <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='shadow mb-5 rounded scrollOnXaxis' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr>
                                <th>Class</th>
                                <th>Subject</th>
                                <th>SNo</th>
                                <th>SBStatus</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.Class}</td>
                                    <td>{item.Subject}</td>
                                    <td>{item.SNo}</td>
                                    <td>{item.Status}</td>
                                    <td><span className='badge badge-warning-lighten mx-2' onClick={() => handleEditClick(item.SBID)}><i className="bi bi-pencil-square fs-6"></i></span></td>
                                    <td><span className='badge badge-danger-lighten mx-2' onClick={() => handleConfirmation(item.SBID)}><i className="bi bi-trash3-fill fs-6"></i></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {isDeleted && (
                    <div className="modal-backdrop show"></div>
                )}
                <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete <strong>Subject?</strong></h5>
                            </div>
                            <div className="modal-body">
                                <p>This Will Delete Subject <span className='text-danger'>Permannently</span></p>
                                <div className='d-flex flex-row-reverse '>
                                    <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
                                    <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
                        <div className="modal-body text-center my-2 mx-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


