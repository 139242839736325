import React, { useEffect, useState, useCallback } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSession } from './SessionContext';
import { Button } from '@mui/material';
import ReactQuill from 'react-quill';
import CryptoJS from 'crypto-js';
import 'react-quill/dist/quill.snow.css';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function AttemptTestPhaseOne() {
    const { encrypteEXID } = useParams();
    const [EXID, setEXID] = useState('');
    const [data, setData] = useState([]);
    const [QBID, setQBID] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [totalSeconds, setTotalSeconds] = useState(0);
    const [question, setQuestion] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionStatus, setQuestionStatus] = useState([]);
    const [questionType, setQuestionType] = useState('');
    const [isCorrectAnswer, setIsCorrectAnswer] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { logo, setLogo, sTRID, setSTRID, sTPhoto, setSTPhoto, studentName, setStudentName } = useSession();

    useEffect(() => {
        var logo = document.cookie.replace(/(?:(?:^|.*;\s*)logo\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTRID = document.cookie.replace(/(?:(?:^|.*;\s*)sTRID\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (sTRID) {
            setLogo(logo);
            setSTRID(sTRID);
            setSTPhoto(sTPhoto);
            setStudentName(studentName);
        }
    }, [setLogo, setSTRID, setSTPhoto, setStudentName]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteEXID) {
            const decryptedId = decryptId(encrypteEXID, secretKey);
            setEXID(decryptedId);
        }
    }, [encrypteEXID]);

    useEffect(() => {
        const fetchExamValidation = async () => {
            try {
                const response = await fetch(`/api/getExamValidationForPS`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ sTRID, EXID }),
                });

                if (response.ok) {
                    const filteredData = await response.json();
                    const examValidation = filteredData[0]

                    if (examValidation.alreadyAttempted > 0) {
                        navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`);
                    } else {
                        toast.warn("Read the precautions carefully before starting the exam.");
                    }
                } else {
                    console.error('Error fetching filtered data');
                }
            } catch (error) {
                console.error('Error fetching filtered data:', error);
            }
        };

        if (sTRID && EXID) {
            fetchExamValidation();
        }
    }, [sTRID, EXID]);

    const fetchExam = useCallback(async () => {
        try {
            const response = await fetch(`/api/getExamTitleAndInstructions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ EXID }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [EXID]);

    const fetchQuestons = useCallback(async () => {
        try {
            const response = await fetch(`/api/getExamQuestions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ EXID }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setQuestion(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [EXID]);

    useEffect(() => {
        if (EXID) fetchExam();
        if (EXID) fetchQuestons();
    }, [EXID, fetchExam, fetchQuestons]);

    useEffect(() => {
        if (question[currentQuestionIndex]) {
            setQBID(question[currentQuestionIndex].QBID);
            if(QBID){
                fetch(`/api/getUpdateQuestions/${QBID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setQuestionType(clientdata.QuestionType);

                        // const studentCorrectAnswer = clientdata.studentCorrectAnswer;
                        // const isCorrectAnswerArray = Array.isArray(studentCorrectAnswer) ? studentCorrectAnswer : studentCorrectAnswer && typeof studentCorrectAnswer === 'string' 
                        // ? studentCorrectAnswer.split(',').map(option => option.trim()) : [];
                        // setIsCorrectAnswer(isCorrectAnswerArray);

                        const optionsArray = clientdata.Options.split(',').map(optionText => optionText.trim());
                        const formattedOptions = optionsArray.map(optionText => ({
                            option: optionText,
                        }));
        
                        setOptions(formattedOptions);
                    }
                })
                .catch((error) => {
                });
            }
        }
    }, [question[currentQuestionIndex], QBID]);

    useEffect(() => {
        if (question[currentQuestionIndex]) {
            setQBID(question[currentQuestionIndex].QBID);
            if(QBID){
                fetch(`/api/getMarkedOptions/${sTRID}/${EXID}/${QBID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        const studentCorrectAnswer = clientdata.studentCorrectAnswer;
                        const isCorrectAnswerArray = Array.isArray(studentCorrectAnswer) ? studentCorrectAnswer : studentCorrectAnswer && typeof studentCorrectAnswer === 'string' 
                        ? studentCorrectAnswer.split(',').map(option => option.trim()) : [];
                        setIsCorrectAnswer(isCorrectAnswerArray);
                    }
                })
                .catch((error) => {
                });
            }
        }
    }, [question[currentQuestionIndex], QBID]);

    useEffect(() => {
        if (data.ExamDuration) {
            const validDuration = Number(data.ExamDuration) || 10;
            const initialTime = validDuration * 60; 
            setTotalSeconds(initialTime);
            setTimeLeft(initialTime);
        }
    }, [data.ExamDuration]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => Math.max(prev - 1, 0));
        }, 1000);

        return () => clearInterval(timer); 
    }, [totalSeconds]);

    const LinearProgressBar = ({ timeLeft, totalSeconds }) => {    
        const progressPercentage = Math.max(0, Math.min((timeLeft / totalSeconds) * 100, 100));
    
        let progressColor;
        if (progressPercentage > 50) {
            progressColor = '#4CAF50'; 
        } else if (progressPercentage > 15) {
            progressColor = '#FF9800'; 
        } else {
            progressColor = '#F44336';
        }
    
        const formatTime = (seconds) => {
            const minutes = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
        };

        useEffect(() => {
            if (timeLeft === 0 && totalSeconds > 0) {
                handleAsyncronus('time end');
            }
        }, [timeLeft, handleAsyncronus]);
    
        return (
            <div className="progress-container" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ marginRight: '10px', fontSize: '15px', fontWeight: 'bold', minWidth: '50px' }}>
                    <TimerTwoToneIcon style={{ marginRight: '8px', marginBottom: '2px', fontSize: '16px' }} />{formatTime(timeLeft)}
                </div>
    
                <div style={{ flexGrow: 1, height: '10px', backgroundColor: '#ddd', borderRadius: '5px', overflow: 'hidden', border: '1px solid #ccc' }}>
                    <div
                        style={{
                            height: '100%',
                            width: `${progressPercentage}%`,
                            backgroundColor: progressColor,
                            borderRadius: '5px',
                            transition: 'width 1s linear'
                        }}
                    />
                </div>
            </div>
        );
    };

    const handleCorrectOptionSelect = (option) => {
        if(questionType === 'Single Answer'){
            setIsCorrectAnswer([option]);
        } else if(questionType === 'Multi Answer') {
            setIsCorrectAnswer((prev) =>
                prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
            );
        }
    }

    const handleNextQuestion = async (status) => {
        const updatedStatus = [...questionStatus];
        updatedStatus[currentQuestionIndex] = status;
        setQuestionStatus(updatedStatus);
        if (currentQuestionIndex < question.length - 1) {
            setLoading(true);

            if (isSubmitting) {
                return;
            }
    
            if(!sTRID || !EXID || !QBID || !isCorrectAnswer || !questionStatus ){
                setLoading(false);
                setIsSubmitting(false);
                toast.error("Something Went Wrong!")
                return;
            }            
            setIsSubmitting(true);

            try {
                const response = await fetch(`/api/fillStudentResult`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ sTRID, EXID, QBID, isCorrectAnswer, questionStatus: status }),
                });

                if (response.ok) {
                    setQBID('');
                    setOptions([]);
                    setIsCorrectAnswer([]);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                    const data = await response.json();
                    toast.error(data.error)
                }
            } catch (error) {
                console.error('Error uploading', error);
            } finally{
                setLoading(false);
            }
            setIsSubmitting(false);
        } else {
            // toast.success("You've completed all questions!");
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handleSubmmitExam = async (status) => {
        setLoading(true);

        if (isSubmitting) {
            return;
        }

        if(!sTRID || !EXID || !QBID || !isCorrectAnswer || !questionStatus ){
            setLoading(false);
            setIsSubmitting(false);
            toast.error("Something Went Wrong!")
            return;
        }            
        setIsSubmitting(true);
        try {
            const response = await fetch(`/api/fillStudentResult`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sTRID, EXID, QBID, isCorrectAnswer, questionStatus: status }),
            });

            if (response.ok) {
                setQBID('');
                setOptions([]);
                setIsCorrectAnswer([]);
                navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`)
            } else {
                const data = await response.json();
                toast.error(data.error)
            }
        } catch (error) {
            console.error('Error uploading', error);
        } finally{
            setLoading(false);
        }
        setIsSubmitting(false);
    };

    const handleAsyncronus = async (status) => {
        setLoading(true);

        if (isSubmitting) {
            return;
        }
         
        setIsSubmitting(true);
        try {
            const response = await fetch(`/api/handleAsyncronus`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sTRID, EXID, QBID, questionStatus: status }),
            });

            if (response.ok) {
                setQBID('');
                setOptions([]);
                setIsCorrectAnswer([]);
                navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`)
            } else {
                const data = await response.json();
                toast.error(data.error)
            }
        } catch (error) {
            console.error('Error uploading', error);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (data.noOfQ) {
            setQuestionStatus(Array(data.noOfQ).fill('default'));
        }
        if (sTRID) {
            fetch(`/api/getTrckOfQuestions/${sTRID}/${EXID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const statuses = result[0].map(item => item.AttemptStatus);
                        setQuestionStatus(statuses);
                    }
                })
                .catch((error) => {
                });
        }
    }, [data.noOfQ, sTRID]);   

    useEffect(() => {
        setShowModal(true);
    }, []);

    const handleFullScreen = () => {
        const elem = document.documentElement;
        if (elem.requestFullscreen) elem.requestFullscreen();
        else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
        else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
        else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
    };

    useEffect(() => {
        const preventKeys = (e) => {
            const blockedKeys = ["Escape", "F1", "F3", "F5", "F7", "F11", "F12"];
    
            if (blockedKeys.includes(e.key)) {
                e.preventDefault(); 
                e.stopPropagation();
                toast.error("Exiting fullscreen will end the exam!!");
            }
            if (e.key === "Escape" && e.keyCode === 122) {
                e.preventDefault(); 
                e.stopPropagation();
                toast.error("Exiting fullscreen will end the exam!!");
            }

            if (e.key === "F11") {
                e.preventDefault();
                e.stopPropagation();
                toast.error("Exiting fullscreen will end the exam!!");
            }
            if (e.ctrlKey && ['c', 'v', 'x', 'z', 'a'].includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
                toast.error(`${e.key.toUpperCase()} is not allowed!`);
            }
        };
    
        window.addEventListener('keydown', preventKeys);
    
        return () => {
            window.removeEventListener('keydown', preventKeys);
        };
    }, []);    

    useEffect(() => {
        const handleFullScreenChange = () => {
          if (!document.fullscreenElement) {
            handleAsyncronus('fullscreen exited');
            toast.error("Exiting fullscreen will end the exam!");
            navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`)
          }
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                handleAsyncronus('switched tab');
              toast.error("Tab switched! The exam will be ended.");
              navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`);
            }
        };

        const handleWindowBlur = () => {
            handleAsyncronus('changed window');
            toast.error("Window changed! The exam will be ended.");
            navigate(`/ExamSubmission/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`);
        };
      
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () =>{ document.removeEventListener('fullscreenchange', handleFullScreenChange);
                      document.removeEventListener('visibilitychange', handleVisibilityChange);
                      window.removeEventListener('blur', handleWindowBlur);
                    }
    }, [handleAsyncronus]);

    return (
        <>
            <style>
              {`           
                .logo {
                  max-width: 200px; 
                  max-height: 200px;
                  width: auto;
                  height: 50px;
                  margin-right: 10px;
                }

                @media (max-width: 750px) {
                    .logo {
                        max-width: 120px; 
                        max-height: 120px;
                        width: auto;
                        height: 45px;
                        margin-right: 10px;
                    }
                }

              `}
            </style>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-light rounded">
                        <div className="modal-header" style={{ borderBottom: '2px solid #c49500', backgroundColor: '#e8d455' }}>
                            <h5 className="modal-title" style={{ color: '#d9640f', fontWeight: 'bold' }}>
                                <i className="fas fa-exclamation-triangle" style={{ marginRight: '10px', color: '#d9640f' }}></i>
                                Exam Started! Important Exam Instructions
                            </h5>
                        </div>
                        <div className="modal-body" style={{ fontSize: '16px', color: '#333' }}>
                            <ul style={{ paddingLeft: '20px' }}>
                                <li>No copying or pasting content during the exam.</li>
                                <li style={{ color: 'red' }}>Exiting fullscreen will automatically submit your exam.</li>
                                <li style={{ color: 'red' }}>Switching tabs/window during the exam will automatically submit your exam.</li>
                                <li>Make sure your internet connection is stable before you start.</li>
                                <li>The timer will start as soon as you begin the exam, and you will not be able to pause it.</li>
                                <li>If you face any technical issues, please report them immediately.</li>
                                <li>Please ensure that you have the required materials before starting.</li>
                                <li>Once the exam ends, your answers will be automatically submitted.</li>
                                <li style={{ color: 'red' }}>Once the exam has started, do not leave the exam page. If you leave, you will not be able to retake the exam.</li>
                            </ul>
                        </div>
                        <div className="modal-footer gap-2">
                            <Button variant="contained" color="secondary" onClick={() => {setShowModal(false); handleFullScreen()}} style={{ backgroundColor: '#f44336', color: '#fff' }}>Close</Button>
                        </div>
                    </div>
                </div>
            </div>
            {!showModal &&
                <>
                <nav className='bg-body-tertiary shadow-sm mb-1 fixed-top py-2'>
                    <div className="row d-flex align-items-center">
                        <div className='col-4'>
                            <h1 className="navbar-brand mb-0 text-muted text-truncate flex-grow-1 mx-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><img src={`/api/logoUploads/${logo}`}alt="Logo" className="mb-0 logo" /></h1>
                        </div>

                        <div className='col-4'> <LinearProgressBar timeLeft={timeLeft} totalSeconds={totalSeconds} /></div>

                        <div className='col-4 d-flex justify-content-end px-0'>
                            <div className="dropdown mx-3">
                                <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0 p-2"
                                    id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"
                                    style={{ border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9" }}>
                                    <img src={sTPhoto ? `/api/Students/${sTPhoto}` : "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png"}
                                        alt="studentprofile" className="profile-image rounded-circle me-2" style={{ width: '32px', height: '32px' }} />
                                    <span className='d-none d-lg-block' style={{ fontSize: "16px", fontWeight: "bold", color: "#333", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {studentName}
                                    </span>
                                </p>
                                <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                                    <li>
                                        <Link className="dropdown-item" to="/StudentProfile?Type=StudentProfile" style={{ display: 'flex', alignItems: 'center' }}>
                                            <AccountCircleTwoToneIcon style={{ marginRight: '1rem' }} /><span className='d-none d-lg-block'>Profile</span><span className='d-lg-none d-block'>{studentName}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div style={{display: 'flex', marginTop: '4.3rem ', height: 'calc(100vh - 4.3rem)'}}>
                    <div className="d-inline-block" style={{ flex: '0 0 50px', overflowY: 'auto', maxHeight: '100%', borderRight: '1px solid #ddd', padding: '2px'}}>
                        {[...Array(data.noOfQ)].map((_, i) => {
                            let className = 'px-3 py-1 border border-secondary';
                            if (i === currentQuestionIndex) className += ' current-question';
                            else if (questionStatus[i] === 'skipped') className += ' skipped-question';
                            else if (questionStatus[i] === 'review') className += ' review-question';
                            else if (questionStatus[i] === 'saved') className += ' saved-question';
                            return(
                                <div key={i} className={className} style={{cursor: 'pointer'}} onClick={() => {setCurrentQuestionIndex(i); setQBID(''); setOptions([]);}}>{i + 1}</div>
                            )
                        })}
                    </div>
                    <div className='row content mx-1'>
                        <div className='col-md-6'>
                            {question[currentQuestionIndex] && (
                                <ReactQuill
                                    modules={{ toolbar: false }}
                                    value={question[currentQuestionIndex].Question}
                                    readOnly={true}
                                    className="no-border-quill"
                                />
                            )}
                        </div>
                        <div className='col-md-6'>
                            <div className='options-container'>
                                <strong className='fw-medium text-muted mb-1 fst-italic'>{questionType === 'Single Answer' ? 'Select the correct option' : 'Select all that apply'}</strong>
                                {options.map((item, index) => (
                                    <div key={index} className={`option-card ${isCorrectAnswer.includes(item.option) ? 'correct' : ''}`}>
                                        <span className="option-text" onClick={() => handleCorrectOptionSelect(item.option)} >{`${String.fromCharCode(65 + index)}. ${item.option}`}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex flex-row-reverse gap-3'>
                            {currentQuestionIndex < question.length - 1 ? (
                                <>
                                    <Button variant='contained' color='success' onClick={() => handleNextQuestion('saved')} disabled={loading}>Save</Button>
                                    <Button variant='contained' color='primary' onClick={() => handleNextQuestion('review')} disabled={loading}>Mark For Review</Button>
                                    <Button variant='contained' color='error' onClick={() => handleNextQuestion('skipped')} disabled={loading}>Skip</Button>
                                </>
                             ) : (
                                <Button variant="contained" color="success" onClick={() => handleSubmmitExam('submitted')}>Submit</Button>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
            <ToastContainer />
        </>
    )
}
