import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import { useSession } from './SessionContext';
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import Face6TwoToneIcon from '@mui/icons-material/Face6TwoTone';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';
import PhonelinkTwoToneIcon from '@mui/icons-material/PhonelinkTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import SouthAmericaTwoToneIcon from '@mui/icons-material/SouthAmericaTwoTone';
import EditLocationTwoToneIcon from '@mui/icons-material/EditLocationTwoTone';
import GpsNotFixedTwoToneIcon from '@mui/icons-material/GpsNotFixedTwoTone';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import ClassTwoToneIcon from '@mui/icons-material/ClassTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import Filter9PlusTwoToneIcon from '@mui/icons-material/Filter9PlusTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import '../CSS/Style.css';

const AdminSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [clicked, setClicked] = useState('');
  const location = useLocation();
  const { userLoginType, setUserLoginType, logo, setLogo, sTPhoto, setSTPhoto, studentName, setStudentName } = useSession();

  useEffect(() => {
    var logo = document.cookie.replace(/(?:(?:^|.*;\s*)logo\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var userLoginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (logo) {
      setLogo(logo);
      setUserLoginType(userLoginType);
      setSTPhoto(sTPhoto);
      setStudentName(studentName);
    }
  }, [setUserLoginType, setLogo, setSTPhoto, setStudentName]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const TypeParam = searchParams.get('Type');
    if (TypeParam) {
      setClicked(TypeParam);
    } else {
      setClicked('');
    }
  }, [location.search]);

  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (clicked) {
      const activeItem = document.querySelector(`.subMenuActive, .activeTab`);
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        activeItem.focus();
      }
    }
  }, [clicked]);

  return (
    <>
      <div className='d-none d-lg-flex' style={{ height: '100vh', backgroundColor: '#1d3557', overflow: 'hidden' }}>
        <Sidebar className="app" collapsed={collapsed} >
          <Menu>
            <div style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff", padding: '5.3px 0px' }}>
              <MenuItem className="menu1" icon={<MenuRoundedIcon className="icon" onClick={collapseSidebar} />}><img src={`/api/logoUploads/${logo}`} alt="Logo" className="mb-0" style={{ maxWidth: '200px', maxHeight: '200px', width: 'auto', height: '50px'}} />
              </MenuItem>
            </div>
            <MenuItem key="Dashboard" className={`${clicked === "Dashboard" ? "subMenuActive" : "sidebar-item"}`} component={<Link to="/Dashboard?Type=Dashboard" className="link" />} icon={<DashboardCustomizeTwoToneIcon className="icon" />}> <span className="text">Dashboard</span> </MenuItem>
            {userLoginType === 'Student' &&
              <>
                <SubMenu key="Online Exam" className={["RegisterStudents", "AttemptTest"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Online Exam</span>} icon={<SchoolTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "AttemptTest" ? "activeTab" : "text-item"}`} component={<Link to="/ViewExams?Type=AttemptTest" className="link" />} icon={<QuizTwoToneIcon className="icon-item" />}><span className="span-item">Attempt Test</span></MenuItem>
                </SubMenu>
              </>
            }
            {userLoginType === 'School' &&
              <>
                <SubMenu key="Registration" className={["RegisterStudents", "ViewRegisterStudents"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Registration</span>} icon={<AppRegistrationTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "RegisterStudents" ? "activeTab" : "text-item"}`} component={<Link to="/RegisterStudents?Type=RegisterStudents" className="link" />} icon={<HowToRegTwoToneIcon className="icon-item" />}><span className="span-item">Student Registration</span></MenuItem>
                  <MenuItem className={`${clicked === "ViewRegisterStudents" ? "activeTab" : "text-item"}`} component={<Link to="/ViewRegisterStudents?Type=ViewRegisterStudents" className="link" />} icon={<PeopleAltTwoToneIcon className="icon-item" />}><span className="span-item">View Students</span></MenuItem>
                </SubMenu>
                <SubMenu key="Result" className={["StudentResultMaster"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Result</span>} icon={<WorkspacePremiumTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "StudentResultMaster" ? "activeTab" : "text-item"}`} component={<Link to="/StudentResultMaster?Type=StudentResultMaster" className="link" />} icon={<Face6TwoToneIcon className="icon-item" />}><span className="span-item">Student Result</span></MenuItem>
                </SubMenu>
              </>
            }
            {userLoginType !== 'School' && userLoginType !== 'Student' &&
              <>
                <SubMenu key="Exam" className={["QuestionBank", "OnlineExam"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Exam</span>} icon={<HistoryEduTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "QuestionBank" ? "activeTab" : "text-item"}`} component={<Link to="/ViewQuestionBank?Type=QuestionBank" className="link" />} icon={<DynamicFormTwoToneIcon className="icon-item" />}><span className="span-item">Question Bank</span></MenuItem>
                  <MenuItem className={`${clicked === "OnlineExam" ? "activeTab" : "text-item"}`} component={<Link to="/ViewOnlineExam?Type=OnlineExam" className="link" />} icon={<PhonelinkTwoToneIcon className="icon-item" />}><span className="span-item">Online Exam</span></MenuItem>
                </SubMenu>
                <SubMenu key="Result" className={["StudentResultMaster"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Result</span>} icon={<WorkspacePremiumTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "StudentResultMaster" ? "activeTab" : "text-item"}`} component={<Link to="/StudentResultMaster?Type=StudentResultMaster" className="link" />} icon={<Face6TwoToneIcon className="icon-item" />}><span className="span-item">Student Result</span></MenuItem>
                </SubMenu>
                <SubMenu key="Affiliated Schools" className={["CreateSchools", "ViewSchools", "ViewSRStudents"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Affiliated Schools</span>} icon={<AccountBalanceTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "CreateSchools" ? "activeTab" : "text-item"}`} component={<Link to="/CreateSchools?Type=CreateSchools" className="link" />} icon={<SchoolTwoToneIcon className="icon-item" />}><span className="span-item">Create School</span></MenuItem>
                  <MenuItem className={`${clicked === "ViewSchools" ? "activeTab" : "text-item"}`} component={<Link to="/ViewSchools?Type=ViewSchools" className="link" />} icon={<ViewListTwoToneIcon className="icon-item" />}><span className="span-item">View School</span></MenuItem>
                  <MenuItem className={`${clicked === "ViewSRStudents" ? "activeTab" : "text-item"}`} component={<Link to="/ViewSRStudents?Type=ViewSRStudents" className="link" />} icon={<PeopleAltTwoToneIcon className="icon-item" />}><span className="span-item">View Registered Students</span></MenuItem>
                </SubMenu>
                <SubMenu key="Setting" className={["CreateRegion", "CreateState", "CreateDistrict", "CreateClasses", "CreateSubject", "DefineExamLevel", "CreateExamSet", "CreateInstructions", "CreateOrganisation"].includes(clicked) ? "subMenuActive" : "sidebar-item"} label={<span className="text">Setting</span>} icon={<TuneTwoToneIcon className="icon" />}>
                  <MenuItem className={`${clicked === "CreateRegion" ? "activeTab" : "text-item"}`} component={<Link to="/CreateRegion?Type=CreateRegion" className="link" />} icon={<SouthAmericaTwoToneIcon className="icon-item" />}><span className="span-item">Create Region</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateState" ? "activeTab" : "text-item"}`} component={<Link to="/CreateState?Type=CreateState" className="link" />} icon={<EditLocationTwoToneIcon className="icon-item" />}><span className="span-item">Create State</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateDistrict" ? "activeTab" : "text-item"}`} component={<Link to="/CreateDistrict?Type=CreateDistrict" className="link" />} icon={<GpsNotFixedTwoToneIcon className="icon-item" />}><span className="span-item">Create District</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateClasses" ? "activeTab" : "text-item"}`} component={<Link to="/CreateClasses?Type=CreateClasses" className="link" />} icon={<ClassTwoToneIcon className="icon-item" />}><span className="span-item">Create Class</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateSubject" ? "activeTab" : "text-item"}`} component={<Link to="/CreateSubject?Type=CreateSubject" className="link" />} icon={<MenuBookTwoToneIcon className="icon-item" />}><span className="span-item">Create Subject</span></MenuItem>
                  <MenuItem className={`${clicked === "DefineExamLevel" ? "activeTab" : "text-item"}`} component={<Link to="/DefineExamLevel?Type=DefineExamLevel" className="link" />} icon={<DriveFileRenameOutlineTwoToneIcon className="icon-item" />}><span className="span-item">Create Exam Level</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateExamSet" ? "activeTab" : "text-item"}`} component={<Link to="/CreateExamSet?Type=CreateExamSet" className="link" />} icon={<Filter9PlusTwoToneIcon className="icon-item" />}><span className="span-item">Create Exam Set</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateInstructions" ? "activeTab" : "text-item"}`} component={<Link to="/CreateInstructions?Type=CreateInstructions" className="link" />} icon={<DescriptionTwoToneIcon className="icon-item" />}><span className="span-item">Create Instructions</span></MenuItem>
                  <MenuItem className={`${clicked === "CreateOrganisation" ? "activeTab" : "text-item"}`} component={<Link to="/CreateOrganisation?Type=CreateOrganisation" className="link" />} icon={<CorporateFareTwoToneIcon className="icon-item" />}><span className="span-item">Create Organisation</span></MenuItem>
                </SubMenu>
              </>
            }
            {studentName && <div style={{ position: "sticky", bottom: 0, zIndex: 1, backgroundColor: "#1d3557" }}>
              <MenuItem className="sidebar-item" component={<Link to="/StudentProfile?Type=StudentProfile" className="link" />} icon={<img src={sTPhoto ? `/api/Students/${sTPhoto}`: "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png" } alt="studentProfile" className="profile-image rounded-circle" /> }>
                {studentName && <span className="text">{studentName}</span>}
              </MenuItem>
            </div>}
          </Menu>
        </Sidebar>
      </div>
    </>
  );
};
export default AdminSidebar;
