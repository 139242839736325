import examAnimation from '../Assets/Animation/Animation - 1730984190271.json'; 
import { TextField, InputAdornment, IconButton, Tooltip} from '@mui/material';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from 'lottie-react'; 
import '../CSS/Login.css'; 
import TheScholarsLoginLogo from '../Assets/TheScholarsLoginLogo.png'
import bgPng from '../Assets/bgPng.png'

const ExamPortalLogIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleAdmin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await fetch(`/api/MyLogIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }), 
      });
      if (response.ok) {
        const data = await response.json(); 
        localStorage.setItem("isLoggedIn", "true");
        document.cookie = "loginType=" + data.loginType;
        document.cookie = "privacyKey=" + data.privacyKey;
        document.cookie = "oId=" + data.oId;
        document.cookie = "orgName=" + data.orgName;
        document.cookie = "logo=" + data.logo;
        navigate('/Dashboard');
        window.location.reload();
      } else {
        toast.error('Username or password is incorrect');
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally{
      setLoading(false);
    }
  };

  const handleSchool = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await fetch(`/api/MySchoolLogIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }), 
      });
      if (response.ok) {
        const data = await response.json(); 
        localStorage.setItem("isLoggedIn", "true");
        document.cookie = "loginType=" + data.loginType;
        document.cookie = "oId=" + data.oId;
        document.cookie = "orgName=" + data.orgName;
        document.cookie = "logo=" + data.logo;
        document.cookie = "sID=" + data.sID;
        document.cookie = "schoolName=" + data.schoolName;
        document.cookie = "schoolLogo=" + data.schoolLogo;
        navigate('/Dashboard');
        window.location.reload();
      } else {
        toast.error('Username or password is incorrect');
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally{
      setLoading(false);
    }
  };

  const handleStudent = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await fetch(`/api/MyStudentLogIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }), 
      });
      if (response.ok) {
        const data = await response.json(); 
        localStorage.setItem("isLoggedIn", "true");
        document.cookie = "loginType=" + data.loginType;
        document.cookie = "oId=" + data.oId;
        document.cookie = "orgName=" + data.orgName;
        document.cookie = "logo=" + data.logo;
        document.cookie = "sID=" + data.sID;
        document.cookie = "schoolName=" + data.schoolName;
        document.cookie = "schoolLogo=" + data.schoolLogo;
        document.cookie = "sTRID=" + data.sTRID;
        document.cookie = "sTPhoto=" + data.sTPhoto;
        document.cookie = "studentName=" + data.studentName;
        navigate('/Dashboard');
        window.location.reload();
      } else {
        toast.error('Username or password is incorrect');
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
    <div className="loginContainer">
      <img src={bgPng} alt='bgPng' />
      <div className="floating-icons"></div>
      <h1 className="orgName"><span>The</span> <strong>Scholars</strong><img src={TheScholarsLoginLogo} alt='TheScholarsLoginLogo' /></h1>
      <p className="tagline">The responsibility of your dreams...</p>
      <div className="loginBox">
        <Lottie animationData={examAnimation} loop={true} autoplay={true} className="lottieAnimation" />
        <h2 className="title">Exam Portal Login</h2>
        <div className="role-buttons">
          <Tooltip title="Admin access for managing the portal" arrow>
            <button onClick={() => setRole('admin')} className={`role-button ${role === 'admin' ? 'active' : ''}`}>Admin</button>
          </Tooltip>
          <Tooltip title="School access for managing student data" arrow>
            <button onClick={() => setRole('school')} className={`role-button ${role === 'school' ? 'active' : ''}`}>School</button>
          </Tooltip>
          <Tooltip title="Student access for viewing exam details" arrow>
            <button onClick={() => setRole('student')} className={`role-button ${role === 'student' ? 'active' : ''}`}>Student</button>
          </Tooltip>
        </div>
        <TextField label="Username" variant="outlined" className="inputField" fullWidth value={username} onChange={(e) => setUsername(e.target.value)} sx={{ marginBottom: '10px' }} InputProps={{endAdornment: (<InputAdornment position="end"><IconButton><PersonTwoToneIcon /></IconButton></InputAdornment>),}}/>
        <TextField label="Password" variant="outlined" className="inputField"  fullWidth value={password} type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} sx={{ marginBottom: '10px' }} InputProps={{endAdornment: (<InputAdornment position="end"> <IconButton onClick={togglePasswordVisibility}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton> </InputAdornment>),}}/>
        <button type="submit" className="loginButton" onClick={(e) => {role === 'admin' ? handleAdmin(e) : (role === 'school' ? handleSchool(e) : (role === 'student' ? handleStudent(e) : handleAdmin(e)))}} disabled={!role}>{role === 'admin' ? 'Admin Login' : (role === 'school' ? 'School Login' : (role === 'student' ? 'Student Login' : 'Please Select Role'))}</button>
      </div>
    </div>
    {loading && (
      <div className="modal-backdrop show"></div>
    )}
    <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
          <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
            <div className="modal-body text-center my-2 mx-2">
             <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
    </div>
    <ToastContainer />
    </>
  );
};

export default ExamPortalLogIn;
