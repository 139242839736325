import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../CSS/QuestionFormat.css'; 
import CryptoJS from 'crypto-js';
import ReactToPrint from 'react-to-print';
import { QuestionFormatPrint } from './QuestionFormatPrint';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function QuestionFormat() {
    const questionFormatPrintRef = useRef(null); 
    const { encrypteQBID } = useParams();
    const [QBID, setQBID] = useState('');
    const [question, setQuestion] = useState('');
    const [questionOptions, setQuestionOptions] = useState([
        { option: '', isCorrect: false },
        { option: '', isCorrect: false },
        { option: '', isCorrect: false },
        { option: '', isCorrect: false }
    ]);
    const [isPrintEnabled, setIsPrintEnabled] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }]);
    const location = useLocation();
    const navigate = useNavigate();

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteQBID) {
            const decryptedId = decryptId(encrypteQBID, secretKey);
            setQBID(decryptedId);
        }
    }, [encrypteQBID]);

    useEffect(() => {
        if (QBID) {
            fetch(`/api/getUpdateQuestions/${QBID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setQuestion(clientdata.Question || '');
                        const optionsArray = clientdata.Options.split(',').map(optionText => optionText.trim());
                        const correctAnswersArray = clientdata.Answers.split(',').map(answerText => answerText.trim());
    
                        const formattedOptions = optionsArray.map(optionText => ({
                            option: optionText,
                            isCorrect: correctAnswersArray.includes(optionText)
                        }));
        
                        setQuestionOptions(formattedOptions);
                    }
                })
                .catch((error) => {
                });
        }
    }, [QBID]);

    useEffect(() => {
        const currentRoute = location.pathname; 
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }];
        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part, index) => {
            const isNumeric = /^\d+$/.test(part);
            const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
            const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);
        
            if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
              urlSoFar += `/${part}`;
              breadcrumbsArray.push({ label: part, url: urlSoFar });
            }
          });

        return breadcrumbsArray;
    };

    return (
        <>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-2'>
                            {isPrintEnabled ? (
                                <ReactToPrint 
                                    trigger={() =><Button variant="contained" color="secondary" fullWidth ><i className="bi bi-printer fs-6"></i> Print</Button>}
                                    content={() => questionFormatPrintRef.current}
                                    onAfterPrint={() => setIsPrintEnabled(false)}
                                />
                            ) : (
                                <Button variant="contained" color="secondary" fullWidth onClick={() => setIsPrintEnabled(true)}>Print</Button>
                            )}
                        </div>
                        <div className='col-2'>
                            <Button variant="contained" color="primary" fullWidth onClick={() => navigate(`/CreateQuestionBank/${encrypteQBID}?Type=QuestionBank`)} className="action-button"><i className="bi bi-pencil-square fs-6"></i> Edit</Button>
                        </div>
                    </div>
                </div>

                <div className='question-container'>
                    <div className='question-text'>
                        <ReactQuill modules={{ toolbar: false }} value={question} readOnly={true} className="no-border-quill" />
                    </div>

                    <div className='options-container'>
                        {questionOptions.map((item, index) => (
                            <div key={index} className={`option-card ${item.isCorrect ? 'correct' : ''}`}>
                                <span className="option-text">{`${String.fromCharCode(65 + index)}. ${item.option}`}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: 'none' }}>
                {QBID && question && questionOptions &&  <QuestionFormatPrint ref={questionFormatPrintRef} QBID={QBID} question={question} questionOptions={questionOptions} />}           
            </div>
        </>
    );
}
