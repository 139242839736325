import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const QuestionFormatPrint = React.forwardRef((props, ref) => {
    const { question, questionOptions } = props;

    return (
        <>
            <style>
                {`  
                  @page {
                    size: A4 portrait;
                    margin: 3mm;
                  }
                `}
            </style>
            <div ref={ref} className="component-to-print">
                <div className='question-container'>
                    <div className='question-text'>
                        <ReactQuill modules={{ toolbar: false }} value={question} readOnly={true} className="no-border-quill" />
                    </div>

                    <div className='options-container'>
                        {questionOptions.map((item, index) => (
                            <div key={index} className={`option-card ${item.isCorrect ? 'correct' : ''}`}>
                                <span className="option-text">{`${String.fromCharCode(65 + index)}. ${item.option}`}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
});
