import { FormControl, InputLabel, Select, MenuItem, Button, Tooltip } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from 'react'
import { useSession } from './SessionContext';
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function StudentResultMaster() {
    const [data, setData] = useState([]);
    const [schoolName, setSchoolName] = useState('');
    const [schoolNameId, setSchoolNameId] = useState('');
    const [schoolNameOptions, setSchoolNameOptions] = useState([]);
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [classOptons, setClassOptions] = useState([]);
    const [exam, setExam] = useState('');
    const [examId, setExamId] = useState('');
    const [examOptions, setExamOptions] = useState([]);
    const [set, setSet] = useState('');
    const [selectedSetId, setSelectedSetId] = useState('');
    const [setOptions, setSetOptions] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();
    const { sID, setSID } = useSession();

    useEffect(() => {
      var sID = document.cookie.replace(/(?:(?:^|.*;\s*)sID\s*=\s*([^;]*).*$)|^.*$/, "$1");
  
      if (sID) {
        setSID(sID);
      }
    }, [setSID]);

    const handleSchoolNameChange = (e) => {
        const value = e.target.value
        setSchoolName(value);
        const selctedCity = schoolNameOptions.find(option => option.name === value)
        if (selctedCity) {
            setSchoolNameId(selctedCity.id)
        }
    }

    const handleSClassChange = (e) => {
        const selectedValue = e.target.value
        setSClass(selectedValue)
        const selectedClasses = classOptons.find(option => option.name === selectedValue);
        if (selectedClasses) {
            setSelectedClassId(selectedClasses.id)
        }
    };

    const handleExamChange = (e) => {
        const selectedValue = e.target.value
        setExam(selectedValue)
        const selectedExam = examOptions.find(option => option.name === selectedValue);
        if (selectedExam) {
            setExamId(selectedExam.id)
        }
    };

    const handleSetChange = (e) => {
        const selectedValue = e.target.value
        setSet(selectedValue)
        const selectedSet = setOptions.find(option => option.name === selectedValue);
        if (selectedSet) {
            setSelectedSetId(selectedSet.id)
        }
    };

    useEffect(() => {        
        const fetchSchool = async () => {
            try {
                const response = await fetch(`/api/getSchoolDetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.filter(option => option.SchoolName !== null).map(option => ({
                        name: option.SchoolName,
                        id: option.SID,
                    }));
                    setSchoolNameOptions(filteredOptions);
                }
            } catch (error) {
                console.error('Internal Server Error');
            }
        };

        const fetchClass = async () => {
            try {
                const response = await fetch(`/api/getClasses`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                        name: option.Class,
                        id: option.CLID,
                    }));
                    setClassOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        const fetchExam = async () => {
            try {
                const response = await fetch(`/api/getExam`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.ExamTitle !== null).map(option => ({
                        name: option.ExamTitle,
                        id: option.EXID,
                    }));
                    setExamOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        const fetchSet = async () => {
            try {
                const response = await fetch(`/api/getSetName`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.SetName !== null).map(option => ({
                        name: option.SetName,
                        id: option.SEID,
                    }));
                    setSetOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchSchool();
        fetchClass();
        fetchExam();
        fetchSet();
    }, []);

    const handleReset = () => {
        setSchoolName('');
        setSchoolNameId('');
        setSClass('');
        setSelectedClassId('');
        setExam('');
        setExamId('');
        setSet('');
        setSelectedSetId('');
    };

    const fetchResults = useCallback(async () => {
        try {
            const schoolId = sID ? sID : schoolNameId
            const response = await fetch(`/api/getRegisteredStudentResults`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ schoolNameId : schoolId, selectedClassId, examId, selectedSetId }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [sID, schoolNameId, selectedClassId, examId, selectedSetId]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    const encryptId = (STRID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(STRID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleViewStudentPerformanceChange=(STRID, EXID) => {
        const encrypteSTRID = encryptId(STRID, secretKey);
        navigate(`/StudentPerformanceDetails/${encodeURIComponent(encrypteSTRID)}/${EXID}?Type=StudentResultMaster`)
    }

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        {!sID && <div className='col-6 col-md-3 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Select School</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={schoolName} onChange={handleSchoolNameChange}>
                                    {schoolNameOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>}
                        <div className='col-6 col-md-3 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Select Class</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                    {classOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={sID ? 'col-6 col-md-3 mb-2' : 'col-4 col-md-2 mb-2'}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Exam</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={exam} onChange={handleExamChange}>
                                    {examOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={sID ? 'col-6 col-md-3 mb-2' : 'col-4 col-md-2 mb-2'}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Set</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={set} onChange={handleSetChange}>
                                    {setOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={sID ? 'col-6 col-md-3 mb-2' : 'col-4 col-md-2 mb-2'}>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                        </div>
                    </div>
                </div>

                <div className='shadow mb-5 rounded' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <th>School Name</th>
                                <th>Exam Title</th>
                                <th>SRNo</th>
                                <th>Student Name</th>
                                <th>Class</th>
                                <th>Total Questions</th>
                                <th>Correct Answers</th>
                                <th>Wrong Answers</th>
                                <th>Unattempt</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <td>{item.SchoolName}</td>
                                    <td>{item.ExamTitle}</td>
                                    <td>{item.SRNo}</td>
                                    <td>{item.StudentName}</td>
                                    <td>{item.Class}</td>
                                    <td><strong className='badge text-bg-primary'>{item.TotalQ}</strong></td>
                                    <td><strong className='badge text-bg-success'>{item.Rans}</strong></td>
                                    <td><strong className='badge text-bg-danger'>{item.Wans}</strong></td>
                                    <td><strong className='badge text-bg-warning'>{item.Uatt}</strong></td>
                                    <td><Tooltip title="View" arrow><strong className='badge badge-success-lighten' onClick={() => handleViewStudentPerformanceChange(item.STRID, item.EXID)}><i className='bi bi-pencil-square fs-6'></i></strong></Tooltip></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

