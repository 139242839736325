import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSession } from './SessionContext';
import { Button, Card, CardContent, Typography, Chip } from '@mui/material';
import { Event, LiveTv } from '@mui/icons-material';
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function ViewExams() {
    const [data, setData] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();
    const { sTRID, setSTRID } = useSession();

    useEffect(() => {
        const sTRID = document.cookie.replace(/(?:(?:^|.*;\s*)sTRID\s*=\s*([^;]*).*$)|^.*$/, "$1");
        if (sTRID) setSTRID(sTRID);
    }, [setSTRID]);

    const fetchExam = useCallback(async () => {
        try {
            const response = await fetch(`/api/getExamDetails`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sTRID }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [sTRID]);

    useEffect(() => {
        if (sTRID) fetchExam();
        const interval = setInterval(fetchExam, 60000);
        return () => clearInterval(interval);
    }, [sTRID, fetchExam]);

    const encryptId = (EXID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(EXID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleAttemptTest = (EXID) => {
        const encrypteEXID = encryptId(EXID, secretKey);
        navigate(`/AttemptTest/${encodeURIComponent(encrypteEXID)}?Type=AttemptTest`)
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];
        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });
        return breadcrumbsArray;
    };

    return (
        <div className='my-2 mx-2'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {breadcrumbs.map((breadcrumb, index) => (
                        <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                            {index === breadcrumbs.length - 1 ? (
                                breadcrumb.label
                            ) : (
                                <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            <div className='exam-container'>
                {data.map((item, index) => {
                    const startDateTime = new Date(`${item.StartDate.slice(0, 10)}T${item.STime.slice(11, 16)}`);
                    const endDateTime = new Date(`${item.EndDate.slice(0, 10)}T${item.ETime.slice(11, 16)}`);
                    const now = new Date();
                    const timeDiff = startDateTime - now;

                    let buttonText = '';
                    let buttonClass = '';

                    if(item.alreadyAttempted > 0){
                        buttonText = 'Attempted';
                        buttonClass = 'secondary';
                    } else if (item.Published === 'Yes') {
                        if (now < startDateTime) {
                            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

                            const parts = [];
                            if (days > 0) parts.push(`${days} days`);
                            if (hours > 0) parts.push(`${hours} hours`);
                            if (minutes > 0) parts.push(`${minutes} minutes`);

                            buttonText = parts.join(', ') + ' remaining'
                            buttonClass = 'error';
                        } else if (now >= startDateTime && now <= endDateTime) {
                            buttonText = 'Start Exam';
                            buttonClass = 'success';
                        } else {
                            buttonText = 'Expired';
                            buttonClass = 'secondary';
                        }
                    }

                    return (
                        <Card key={index} className='exam-card mb-4 shadow'>
                            <CardContent>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <Typography variant="h5" component="h2" className='text-uppercase'>
                                        {item.ExamTitle}
                                    </Typography>
                                    <Chip
                                        label={item.Published === 'Yes' ? 'Live' : 'Expired'}
                                        color={item.Published === 'Yes' ? 'success' : 'error'}
                                        icon={<LiveTv />}
                                    />
                                </div>

                                <Typography color="textSecondary" className='mt-2'>
                                    <Event /> Start Date: {new Date(item.StartDate).toLocaleDateString([], { day: 'numeric', month: 'long', year: 'numeric' })}
                                    {' '}at {new Date(item.STime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' })}
                                </Typography>

                                <Typography color="textSecondary" className='mt-2'>
                                    <Event /> End Date: {new Date(item.EndDate).toLocaleDateString([], { day: 'numeric', month: 'long', year: 'numeric' })}
                                    {' '}at {new Date(item.ETime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' })}
                                </Typography>

                                <Typography variant="body2" color="textSecondary" className="mt-3">
                                    <strong>Duration: </strong>{item.ExamDuration}
                                </Typography>

                                <Button variant="contained" color={buttonClass} fullWidth disabled={item.Published !== 'Yes' || item.alreadyAttempted > 0} onClick={() => {if (buttonClass === 'success') {handleAttemptTest(item.EXID);}}}>
                                    {buttonText}
                                </Button>
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
}
