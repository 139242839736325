import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function ViewOnlineExam() {
    const [data, setData] = useState([]);
    const [set, setSet] = useState('');
    const [selectedSetId, setSelectedSetId] = useState('');
    const [setOptions, setSetOptions] = useState([]);
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [classOptons, setClassOptions] = useState([]);
    const [subject, setSubject] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');
    const [subjectOptons, setSubjectOptions] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSetChange = (e) => {
        const selectedValue = e.target.value
        setSet(selectedValue)
        const selectedSet = setOptions.find(option => option.name === selectedValue);
        if (selectedSet) {
            setSelectedSetId(selectedSet.id)
        }
    };

    const handleSClassChange = (e) => {
        const selectedValue = e.target.value
        setSClass(selectedValue)
        const selectedClasses = classOptons.find(option => option.name === selectedValue);
        if (selectedClasses) {
            setSelectedClassId(selectedClasses.id)
        }
        setSubject('');
        setSelectedSubjectId('');
    };

    const handleSubjectChange = (e) => {
        const selectedValue = e.target.value
        setSubject(selectedValue)
        const selectedSubject = subjectOptons.find(option => option.name === selectedValue);
        if (selectedSubject) {
            setSelectedSubjectId(selectedSubject.id)
        }
    };

    useEffect(() => {
        const fetchSet = async () => {
            try {
                const response = await fetch(`/api/getSetName`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.SetName !== null).map(option => ({
                        name: option.SetName,
                        id: option.SEID,
                    }));
                    setSetOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchClass = async () => {
            try {
                const response = await fetch(`/api/getClasses`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                        name: option.Class,
                        id: option.CLID,
                        min: option.MinAge,
                        max: option.MaxAge
                    }));
                    setClassOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchSubject = async () => {
            try {
                const response = await fetch(`/api/getSubjectByClass/${selectedClassId}`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Subject !== null).map(option => ({
                        name: option.Subject,
                        id: option.SBID,
                    }));
                    setSubjectOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchSet();
        fetchClass();
        if (selectedClassId) {
            fetchSubject();
        }
    }, [selectedClassId]);

    const handleReset = () => {
        setSet('');
        setSelectedSetId([]);
        setSClass('');
        setSelectedClassId([]);
        setSubject('');
        setSelectedSubjectId([]);
    };

    const fetchExam = useCallback(async () => {
        try {
            const response = await fetch(`/api/getFilteredExamDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selectedSetId, selectedClassId, selectedSubjectId }),
            });

            if (response.ok) {
                const filteredData = await response.json();
                setData(filteredData);
            } else {
                console.error('Error fetching filtered data');
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }, [selectedSetId, selectedClassId, selectedSubjectId]);

    useEffect(() => {
        fetchExam();
    }, [fetchExam]);

    const encryptId = (EXID, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(EXID.toString(), secretKey).toString();
        const urlSafeBase64 = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return urlSafeBase64;
    };

    const handleEditClick = (EXID) => {
        const encrypteEXID = encryptId(EXID, secretKey);
        navigate(`/CreateOnlineExam/${encodeURIComponent(encrypteEXID)}?Type=OnlineExam`)
    };

    const handlePublishedUpdate = async (EXID, published) => {
        try {
          const response = await fetch(`/api/updatePublish`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ EXID, published }),
          });
      
          
          if (response.ok) {
            const data = await response.json();
            toast.success(data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          } else {
            const data = await response.json();
            toast.error(data.error, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          }
        } catch (error) {
          console.error('Error updating:', error);
        }
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-4 col-md-2 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Set</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={set} onChange={handleSetChange}>
                                    {setOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-4 col-md-2 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Class</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                    {classOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-4 col-md-2 mb-2'>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Subject</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={subject} onChange={handleSubjectChange}>
                                    {subjectOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-6 col-md-3'>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                        </div>
                        <div className='col-6 col-md-3'>
                            <Button variant="contained" color="primary" fullWidth onClick={() => navigate(`/CreateOnlineExam?Type=OnlineExam`)} >Add Online Exam</Button>
                        </div>
                    </div>
                </div>

                <div className='shadow mb-5 rounded' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap table-striped mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr>
                                <th>#</th>
                                <th>Exam Title</th>
                                <th>SetName</th>
                                <th>StartDate</th>
                                <th>EndDate</th>
                                <th>Published</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.ExamTitle}</td>
                                    <td>{item.SetName}</td>
                                    <td>{item.StartDate.slice(0, 10)+ '/' +item.STime.slice(11, 16)}</td>
                                    <td>{item.EndDate.slice(0, 10)+ '/' +item.ETime.slice(11, 16)}</td>
                                    <td className='d-md-table-cell d-lg-table-cell'><div className="form-switch"><input defaultChecked={item.Published === 'Yes'} className='form-check-input' type="checkbox"  role="switch" onChange={(e) => {const published = e.target.checked ? 'Yes' : 'No'; handlePublishedUpdate(item.EXID, published); }}/></div></td>
                                    <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.EXID)}><i className="bi bi-pencil-square fs-6"></i></p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
