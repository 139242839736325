import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Button, Rating, TextField, Box, Typography } from "@mui/material";
import { useSession } from './SessionContext';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function ExamSubmission() {
    const { encrypteEXID } = useParams();
    const [EXID, setEXID] = useState('');
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { logo, setLogo, sTRID, setSTRID, sTPhoto, setSTPhoto, studentName, setStudentName } = useSession();

    useEffect(() => {
        var logo = document.cookie.replace(/(?:(?:^|.*;\s*)logo\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTRID = document.cookie.replace(/(?:(?:^|.*;\s*)sTRID\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (sTRID) {
            setLogo(logo);
            setSTRID(sTRID);
            setSTPhoto(sTPhoto);
            setStudentName(studentName);
        }
    }, [setLogo, setSTRID, setSTPhoto, setStudentName]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteEXID) {
            const decryptedId = decryptId(encrypteEXID, secretKey);
            setEXID(decryptedId);
        }
    }, [encrypteEXID]);

    const exitFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen?.();
        }
    };

    useEffect(() => {
        exitFullScreen();
    }, []);

    const handleSubmmitFeedback = async () => {
        exitFullScreen();

        if (isSubmitting) {
            return;
        }
       
        setIsSubmitting(true);
        try {
            const response = await fetch(`/api/SubmitFeedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sTRID, EXID, rating, comments}),
            });

            if (response.ok) {
                navigate(`/ViewExams?Type=AttemptTest`)
            } else {
                const data = await response.json();
                toast.error(data.error)
            }
        } catch (error) {
            console.error('Error uploading', error);
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <style>
              {`           
                .logo {
                  max-width: 200px; 
                  max-height: 200px;
                  width: auto;
                  height: 50px;
                  margin-right: 10px;
                }

                @media (max-width: 750px) {
                    .logo {
                        max-width: 120px; 
                        max-height: 120px;
                        width: auto;
                        height: 45px;
                        margin-right: 10px;
                    }
                }

              `}
            </style>
            <nav className='bg-body-tertiary shadow-sm mb-1 fixed-top py-2'>
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <h1 className="navbar-brand mb-0 text-muted text-truncate flex-grow-1 mx-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><img src={`/api/logoUploads/${logo}`}alt="Logo" className="mb-0 logo" /></h1>

                    <div className='col-4 d-flex justify-content-end px-0'>
                        <div className="dropdown mx-3">
                            <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0 p-2"
                                id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"
                                style={{ border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9" }}>
                                <img src={sTPhoto ? `/api/Students/${sTPhoto}` : "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png"}
                                    alt="studentprofile" className="profile-image rounded-circle me-2" style={{ width: '32px', height: '32px' }} />
                                <span className='d-none d-lg-block' style={{ fontSize: "16px", fontWeight: "bold", color: "#333", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {studentName}
                                </span>
                            </p>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                                <li>
                                    <Link className="dropdown-item" to="/StudentProfile?Type=StudentProfile" style={{ display: 'flex', alignItems: 'center' }}>
                                        <AccountCircleTwoToneIcon style={{ marginRight: '1rem' }} /><span className='d-none d-lg-block'>Profile</span><span className='d-lg-none d-block'>{studentName}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <Box sx={{ margin: "5rem auto", width: "100%", maxWidth: "600px", padding: "2rem", backgroundColor: "#f9f9f9", border: "1px solid #ddd", borderRadius: "8px" }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>Feedback</Typography>
                <Typography variant="body1" gutterBottom sx={{ color: "#666" }}>Your feedback helps us improve. Let us know about your experience!</Typography>
                <Rating value={rating} onChange={(event, newValue) => setRating(newValue)} precision={0.5} sx={{ marginBottom: "1rem", fontSize: "2rem" }}/>
                <TextField fullWidth multiline rows={4} label="Leave your comments here (optional)" value={comments} onChange={(e) => setComments(e.target.value)} sx={{ marginBottom: "1rem", "& .MuiOutlinedInput-root": { borderRadius: "8px",   backgroundColor: "#fff", }}}/>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button variant="outlined" color="secondary" onClick={() => navigate("/ViewExams?Type=AttemptTest")}>Skip</Button>
                  <Button variant="contained" color="primary" onClick={handleSubmmitFeedback}>Submit Feedback</Button>
                </Box>
            </Box>
            <ToastContainer />
        </>
    )
}

