const admIdReducer = (state = null, action) => {
    // console.log('admIdReducer action:', action);
    switch (action.type) {
        case 'SET_ADM_ID':
            return  action.payload;
        default:
            return state;
    }
};

export default admIdReducer;
