import { TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, IconButton, Radio } from '@mui/material';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSession } from './SessionContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function CreateQuestionBank() {
    const { encrypteQBID } = useParams();
    const [QBID, setQBID] = useState('');
    const [set, setSet] = useState('');
    const [selectedSetId, setSelectedSetId] = useState('');
    const [setOptions, setSetOptions] = useState([]);
    const [level, setLevel] = useState('');
    const [selectedLevelId, setSelectedLevelId] = useState('');
    const [levelOptons, setLevelOptions] = useState([]);
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [classOptons, setClassOptions] = useState([]);
    const [subject, setSubject] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');
    const [subjectOptons, setSubjectOptions] = useState([]);
    const [question, setQuestion] = useState('');
    const [questionType, setQuestionType] = useState('');
    const questionTypeOptions = ['Single Answer', 'Multi Answer'];
    const [questionOptions, setQuestionOptions] = useState([
        { option: '', isCorrect: false },
        { option: '', isCorrect: false },
        { option: '', isCorrect: false },
        { option: '', isCorrect: false }
    ]);
    const [errors, setErrors] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }]);
    const location = useLocation();
    const navigate = useNavigate();
    const { userLoginType, setUserLoginType } = useSession();

    useEffect(() => {
        var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (loginType) {
            setUserLoginType(loginType);
        }
    }, [setUserLoginType]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteQBID) {
            const decryptedId = decryptId(encrypteQBID, secretKey);
            setQBID(decryptedId);
        }
    }, [encrypteQBID]);

    const handleSetChange = (e) => {
        const selectedValue = e.target.value
        setSet(selectedValue)
        const selectedSet = setOptions.find(option => option.name === selectedValue);
        if (selectedSet) {
            setSelectedSetId(selectedSet.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedSetId: selectedValue ? '' : ''
        }));
    };

    const handleLevelChange = (e) => {
        const selectedValue = e.target.value
        setLevel(selectedValue)
        const selectedLevel = levelOptons.find(option => option.name === selectedValue);
        if (selectedLevel) {
            setSelectedLevelId(selectedLevel.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedLevelId: selectedValue ? '' : ''
        }));
    };

    const handleSClassChange = (e) => {
        const selectedValue = e.target.value
        setSClass(selectedValue)
        const selectedClasses = classOptons.find(option => option.name === selectedValue);
        if (selectedClasses) {
            setSelectedClassId(selectedClasses.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedClassId: selectedValue ? '' : ''
        }));
        setSubject('');
        setSelectedSubjectId('');
    };

    const handleSubjectChange = (e) => {
        const selectedValue = e.target.value
        setSubject(selectedValue)
        const selectedSubject = subjectOptons.find(option => option.name === selectedValue);
        if (selectedSubject) {
            setSelectedSubjectId(selectedSubject.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedSubjectId: selectedValue ? '' : ''
        }));
    };

    const handleQuestionChange = (value) => {
        setQuestion(value)
        setErrors((prevErrors) => ({
            ...prevErrors,
            question: value ? '' : ''
        }));
    };

    const handleQuestionTypeChange = (e) => {
        setQuestionType(e.target.value)
        setQuestionOptions([
            { option: '', isCorrect: false },
            { option: '', isCorrect: false },
            { option: '', isCorrect: false },
            { option: '', isCorrect: false }
        ]);
        setErrors((prevErrors) => ({
            ...prevErrors,
            questionType: e.target.value ? '' : ''
        }));
    };

    const handleOptionTextChange = (index, value) => {
        const updatedOptions = [...questionOptions];
        updatedOptions[index].option = value;
        setQuestionOptions(updatedOptions);
    };

    const handleSingleCorrectOptionChange = (index) => {
        const updatedOptions = questionOptions.map((option, i) => ({
            ...option,
            isCorrect: i === index
        }));
        setQuestionOptions(updatedOptions);
    };

    const handleMultiCorrectOptionChange = (index) => {
        const updatedOptions = questionOptions.map((option, i) => {
            if (i === index) {
                return {
                    ...option,
                    isCorrect: !option.isCorrect
                };
            }
            return option;
        });
        setQuestionOptions(updatedOptions);
    };

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setFailureMessage('');
        setShowModal(false);
    };

    const handleReset = () => {
        setSet('');
        setSelectedSetId([]);
        setLevel('');
        setSelectedLevelId([]);
        setSClass('');
        setSelectedClassId([]);
        setSubject('');
        setSelectedSubjectId([]);
        setQuestion('');
        setQuestionType('');
        setQuestionOptions([
            { option: '', isCorrect: false },
            { option: '', isCorrect: false },
            { option: '', isCorrect: false },
            { option: '', isCorrect: false }
        ]);
    };

    useEffect(() => {
        const fetchSet = async () => {
            try {
                const response = await fetch(`/api/getSetName`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.SetName !== null).map(option => ({
                        name: option.SetName,
                        id: option.SEID,
                    }));
                    setSetOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchElevel = async () => {
            try {
                const response = await fetch(`/api/getExamLevels`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.SetName !== null).map(option => ({
                        name: option.DLevel,
                        id: option.DLID,
                    }));
                    setLevelOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchClass = async () => {
            try {
                const response = await fetch(`/api/getClasses`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                        name: option.Class,
                        id: option.CLID,
                        min: option.MinAge,
                        max: option.MaxAge
                    }));
                    setClassOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchSubject = async () => {
            try {
                const response = await fetch(`/api/getSubjectByClass/${selectedClassId}`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Subject !== null).map(option => ({
                        name: option.Subject,
                        id: option.SBID,
                    }));
                    setSubjectOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchSet();
        fetchElevel();
        fetchClass();
        if (selectedClassId) {
            fetchSubject();
        }
    }, [selectedClassId]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (isSubmitting) {
            return;
        }

        if (!selectedSetId || !selectedLevelId || !selectedClassId || !selectedSubjectId || !question || !questionType) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                selectedSetId: !selectedSetId ? 'Please Select Exam Set' : prevErrors.selectedSetId,
                selectedLevelId: !selectedLevelId ? 'Please Select Exam Level' : prevErrors.selectedLevelId,
                selectedClassId: !selectedClassId ? 'Please Select Class' : prevErrors.selectedClassId,
                selectedSubjectId: !selectedSubjectId ? 'Please Select Subject' : prevErrors.selectedSubjectId,
                question: !question ? 'Please Enter The Question' : prevErrors.question,
                questionType: !questionType ? 'Please Define Question Type' : prevErrors.questionType,
            }));
    
            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        if( questionOptions.some(option => option.option.trim() === '')){
            toast.error('Please define all the options.');
            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        if(!questionOptions.some(option => option.isCorrect)){
            toast.error('Please mark the correct answer from the provided options.');
            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        setIsSubmitting(true);

        if (QBID) {
            try {
                const response = await fetch(`/api/UpdateQuestionBank/${QBID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedSetId, selectedLevelId, selectedClassId, selectedSubjectId, question, questionType, questionOptions, userLoginType }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    handleReset();
                    navigate(`/ViewQuestionBank?Type=QuestionBank`);
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                }
            } catch (error) {
                console.error('Error updating department:', error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/CreateQuestionBank`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedSetId, selectedLevelId, selectedClassId, selectedSubjectId, question, questionType, questionOptions, userLoginType }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    handleReset();
                    navigate(`/ViewQuestionBank?Type=QuestionBank`);
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (QBID) {
            fetch(`/api/getUpdateQuestions/${QBID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setSet(clientdata.SetName || '');
                        setSelectedSetId(clientdata.SEID || '');
                        setLevel(clientdata.DLevel || '');
                        setSelectedLevelId(clientdata.DLID || '');
                        setSClass(clientdata.Class || '');
                        setSelectedClassId(clientdata.CLID || '');
                        setSubject(clientdata.Subject || '');
                        setSelectedSubjectId(clientdata.SBID || '');
                        setQuestion(clientdata.Question || '');
                        setQuestionType(clientdata.QuestionType || '');
                        const optionsArray = clientdata.Options.split(',').map(optionText => optionText.trim());
                        const correctAnswersArray = clientdata.Answers.split(',').map(answerText => answerText.trim());
    
                        const formattedOptions = optionsArray.map(optionText => ({
                            option: optionText,
                            isCorrect: correctAnswersArray.includes(optionText)
                        }));
        
                        setQuestionOptions(formattedOptions);
                    }
                })
                .catch((error) => {
                });
        }
    }, [QBID]);

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part, index) => {
            const isNumeric = /^\d+$/.test(part);
            const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
            const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);
        
            if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
              urlSoFar += `/${part}`;
              breadcrumbsArray.push({ label: part, url: urlSoFar });
            }
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Success Message</h5>
                            <span onClick={removeSuccessMessage}><i className="bi bi-x-lg fs-6"></i></span>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-3 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.selectedSetId}>
                                <InputLabel id="demo-simple-select-standard-label">Set</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={set} onChange={handleSetChange}>
                                    {setOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.selectedSetId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-3 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.selectedLevelId}>
                                <InputLabel id="demo-simple-select-standard-label">Level</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={level} onChange={handleLevelChange}>
                                    {levelOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.selectedLevelId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-3 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.selectedClassId}>
                                <InputLabel id="demo-simple-select-standard-label">Class</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                    {classOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.selectedClassId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-3 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.selectedSubjectId}>
                                <InputLabel id="demo-simple-select-standard-label">Subject</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={subject} onChange={handleSubjectChange}>
                                    {subjectOptons.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.selectedSubjectId}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className='col-12 mb-2'>
                            <FormControl variant="standard" fullWidth error={!!errors.question}>
                                <ReactQuill modules={{
                                    toolbar: [[{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
                                    [{ 'size': ['small', 'medium', 'large', 'huge'] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    ['clean'],
                                    [{ 'script': 'sub' }, { 'script': 'super' }],
                                    ['blockquote', 'code-block'],
                                    [{ 'background': [] }, { 'color': [] }],],
                                }} value={question} onChange={handleQuestionChange} placeholder='Enter The Question' />
                                <FormHelperText>{errors.question}</FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className='row mx-1'>
                    <div className='col-4'>
                        <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                            <FormControl variant="standard" fullWidth error={!!errors.questionType}>
                                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={questionType} onChange={handleQuestionTypeChange}>
                                    {questionTypeOptions.map((option, index) => (
                                        <MenuItem key={index} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors.questionType}</FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                    {questionType &&<div className='col-8'>
                        <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                            <div className='row'>
                                {questionType === 'Single Answer' && 
                                    questionOptions.map((item, index) => (
                                        <div key={index} className="col-12 mb-2">
                                            <TextField
                                                label={`Option ${index + 1}`} variant="outlined" type="text" fullWidth value={questionOptions[index].option} onChange={(e) => handleOptionTextChange(index, e.target.value)}
                                                helperText={errors[`option${index}`]} error={!!errors[`option${index}`]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton>
                                                                <Radio checked={item.isCorrect} onChange={() => handleSingleCorrectOptionChange(index)} name="correctOption" color="primary" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                                {questionType === 'Multi Answer' && 
                                    questionOptions.map((item, index) => (
                                        <div key={index} className="col-12 mb-2">
                                            <TextField
                                                label={`Option ${index + 1}`} variant="outlined" type="text" fullWidth value={questionOptions[index].option} onChange={(e) => handleOptionTextChange(index, e.target.value)}
                                                helperText={errors[`option${index}`]} error={!!errors[`option${index}`]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton>
                                                                <Radio checked={item.isCorrect} onChange={() => handleMultiCorrectOptionChange(index)} name="correctOption" color="primary" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='d-flex justify-content-center' style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', opacity: 1, cursor: 'pointer', zIndex: '101' }}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>{QBID ? 'Update' : 'Save'}</Button>
                    <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                </div>

            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
                        <div className="modal-body text-center my-2 mx-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
