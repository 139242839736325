import { TextField, Button } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';

export default function CreateClasses() {
    const [data, setData] = useState([]);
    const [CLID, setCLID] = useState('');
    const [className, setClassName] = useState('');
    const [slNo, setSlNo] = useState('');
    const [errors, setErrors] = useState('');
    const [DeleteId, setDeleteId] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const { userLoginType, setUserLoginType } = useSession();

    useEffect(() => {
        var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (loginType) {
            setUserLoginType(loginType);
        }
    }, [setUserLoginType]);

    const handleClassNameChange = (e) => {
        const value = e.target.value
        setClassName(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            className: value ? '' : 'Please Enter Class Name'
        }));
    }

    const handleSlNoChange = (e) => {
        const value = e.target.value
        setSlNo(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            slNo: value ? '' : 'Please Enter Serial No'
        }))
    }

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setShowModal(false);
    };

    const handleReset = () => {
        setClassName('');
        setSlNo('');
        setCLID('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        if (!className || !slNo) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                className: !className ? 'Class is required' : prevErrors.className,
                slNo: !slNo ? "Serial No is required" : prevErrors.slNo,
            }));

            setLoading(false);
            setIsSubmitting(false);
            return;
        }

        setIsSubmitting(true);
        setLoading(true);

        if (CLID) {
            try {
                const response = await fetch(`/api/UpdateClasses/${CLID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ className, slNo, userLoginType }),
                });
                if (response.ok) {
                    const Data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(Data.message);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 1000);
                    handleReset();
                    fetchClass();
                } else {
                    const Data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(Data.error);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 1000);
                }
            } catch (error) {
                console.error('Internal Server Error');
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/CreateClasses`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ className, slNo, userLoginType }),
                });

                if (response.ok) {
                    const Data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(Data.message);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 1000);
                    handleReset();
                    fetchClass();
                } else {
                    const Data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(Data.error);
                    setTimeout(() => {
                        removeSuccessMessage();
                    }, 1000);
                }
            } catch (error) {
                console.error('Internal Server Error');
            } finally {
                setLoading(false);
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        fetchClass();
    }, []);

    const fetchClass = async () => {
        try {
            const response = await fetch(`/api/getClasses`);
            const data = await response.json();

            const dataArray = data.recordset;
            if (Array.isArray(dataArray)) {
                setData(dataArray);
            } else {
                console.error('Invalid data format from the API:', data);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        if (CLID) {
            fetch(`/api/getUpdateClasses/${CLID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setClassName(clientdata.Class || '');
                        setSlNo(clientdata.SLNo || '');
                    }
                })
                .catch((error) => {
                    console.error('Internal Server Error');
                });
        }
    }, [CLID]);

    const handleEditClick = (CLID) => {
        setCLID(CLID);
    };

    const handleConfirmation = async (CLID) => {
        setIsDeleted(true);
        setDeleteId(CLID)
    };

    const handleCancel = async () => {
        setIsDeleted(false);
        setDeleteId('')
    };

    const handleDelete = async (CLID) => {
        try {
            const response = await fetch(`/api/DeleteClass/${CLID}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setData((prevData) => prevData.filter(item => item.CLID !== CLID));
                const Data = await response.json();
                setIsDeleted(false)
                setShowModal(true);
                setFailureMessage('');
                setSuccessMessage(Data.message);
                setTimeout(() => {
                    removeSuccessMessage();
                }, 2000);
                DeleteId('');
            } else {
                const Data = await response.json();
                setIsDeleted(false);
                setShowModal(true);
                setSuccessMessage('');
                setFailureMessage(Data.error);
                setTimeout(() => {
                    removeSuccessMessage();
                }, 2000);
            }
        } catch (error) {
            console.error('Error deleting Class:', error);
        }
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Success Message</h5>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label="Class Name" variant="standard" fullWidth value={className} onChange={handleClassNameChange} helperText={errors.className} error={!!errors.className} />
                        </div>
                        <div className='col-6 col-md-3 mb-2'>
                            <TextField label="SL No" variant="standard" type='number' className='text-end' fullWidth value={slNo} onChange={handleSlNoChange} helperText={errors.slNo} error={!!errors.slNo} />
                        </div>
                        <div className='col-6 col-md-3'>
                            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} >{CLID ? 'Update' : 'Save'}</Button>
                        </div>
                        <div className='col-6 col-md-3'>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                        </div>
                    </div>
                </div>

                <div className='shadow mb-5 rounded' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <table className='table table-nowrap mb-0 mt-0'>
                        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem' }}>
                            <tr>
                                <th>Class</th>
                                <th>SL No</th>
                               <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.Class}</td>
                                    <td>{item.SLNo}</td>
                                    <td><p className='badge badge-warning-lighten' onClick={() => handleEditClick(item.CLID)}><i className="bi bi-pencil-square fs-6"></i></p></td>
                                    <td><p className='badge badge-danger-lighten mx-2' onClick={() => handleConfirmation(item.CLID)}><i className="bi bi-trash-fill fs-6"></i></p></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {isDeleted && (
                    <div className="modal-backdrop show"></div>
                )}
                <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete <strong>Class?</strong></h5>
                            </div>
                            <div className="modal-body">
                                <p>This Will Delete Class <span className='text-danger'>Permannently</span></p>
                                <div className='d-flex flex-row-reverse '>
                                    <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
                                    <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
                        <div className="modal-body text-center my-2 mx-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

