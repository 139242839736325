import { Link, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../CSS/QuestionFormat.css'; 
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function StudentPerformanceDetails() {
    const { encrypteSTRID, EXID } = useParams();
    const [STRID, setSTRID] = useState('');
    const [question, setQuestion] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }]);
    const location = useLocation();

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteSTRID) {
            const decryptedId = decryptId(encrypteSTRID, secretKey);
            setSTRID(decryptedId);
        }
    }, [encrypteSTRID]);

    useEffect(() => {
        if (STRID && EXID) {
            fetch(`/api/getStudentResultsPerformance/${STRID}/${EXID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result;
                        setQuestion(clientdata || '');        
                    }
                })
                .catch((error) => {
                });
        }
    }, [STRID, EXID]);

    useEffect(() => {
        const currentRoute = location.pathname; 
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Question Bank', url: '/ViewQuestionBank?Type=QuestionBank' }];
        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part, index) => {
            const isNumeric = /^\d+$/.test(part);
            const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
            const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);
        
            if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
              urlSoFar += `/${part}`;
              breadcrumbsArray.push({ label: part, url: urlSoFar });
            }
          });

        return breadcrumbsArray;
    };

    return (
        <>
            <style>
                {`
                    .option-card {
                      padding: 8px;
                      border: 1px solid #ccc;
                      margin-bottom: 8px;
                      border-radius: 5px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }

                    .option-card.correct {
                      border-color: green;
                      background-color: #e0f7e9;
                    }

                    .option-card.incorrect {
                      border-color: red;
                      background-color: #fbe9e9;
                    }

                    .option-card.unattempted {
                      border-color: orange;
                    }

                    .text-warning {
                      color: orange;
                      font-weight: bold;
                    }

                    .correct-answers {
                      background-color: #f1f1f1;
                      padding: 5px;
                      border-radius: 5px;
                    }
                `}
            </style>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className="row mx-2">
                    {question.map((item, index) => {
                        const correctAnswers = item.Answers?.split(', ').map(ans => ans.trim());
                        const markedAnswers = item.Marked?.split(', ').map(ans => ans.trim());
                        const filteredMarkedAnswers = markedAnswers?.filter(ans => ans.trim() !== '');
                        const isUnattempted = !filteredMarkedAnswers || filteredMarkedAnswers.length === 0;                        

                        return (
                            <div className="col-6" key={index}>
                                <div className={`question-card ${isUnattempted ? 'unattempted' : ''}`}>
                                    <div className="d-flex align-items-center">
                                        <strong>{index + 1}.</strong><ReactQuill modules={{ toolbar: false }} value={item.Question} readOnly={true} className="no-border-quill" />
                                    </div>

                                    <div className="options-container mt-2">
                                        {item.Options.split(', ').map((option, optionIndex) => {
                                            const isCorrect = correctAnswers?.includes(option.trim());
                                            const isMarked = markedAnswers?.includes(option.trim());
                                            const optionStatus = isMarked ? isCorrect ? 'correct' : 'incorrect' : '';

                                            return (
                                                <div key={optionIndex} className={`option-card ${optionStatus}`}>
                                                    <span className="option-text">{`${String.fromCharCode(65 + optionIndex)}. ${option}`}</span>
                                                    {isMarked && (<span className={`badge ${optionStatus}`}>{isCorrect ? '✔' : '✖'}</span>)}
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {isUnattempted && <div className="text-warning">Unattempted</div>}

                                    <div className="correct-answers mt-2">
                                      <strong>Correct Answer:</strong> {correctAnswers?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className='d-flex justify-content-center align-items-center gap-3 shadow-sm p-3 mb-2 bg-white rounded' style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', opacity: 1, cursor: 'pointer', zIndex: '101' }}>
                    <strong>Total Questions:</strong> <strong className='badge text-bg-primary'>{question.length}</strong>
                    <strong>Total Correct:</strong> <strong className='badge text-bg-success'>{question.filter(item => item.Rans).length}</strong> 
                    <strong>Total Wrong:</strong> <strong className='badge text-bg-danger'>{question.filter(item => item.Wans).length}</strong>
                    <strong>Total Unattempted:</strong> <strong className='badge text-bg-warning'>{question.filter(item => item.Uatt).length}</strong>
                </div>
            </div>
        </>
    );
}

