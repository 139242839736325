import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TextField, Button, FormControl, InputLabel, Select, FormHelperText, MenuItem, InputAdornment, Checkbox, FormControlLabel} from '@mui/material';
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function RegisterStudents () {
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = `${now.getMonth() + 1}`.padStart(2, '0');
      const day = `${now.getDate()}`.padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const {encrypteSTRID} = useParams();
    const [STRID, setSTRID] = useState('');
    const [SRNo, setSRNo] = useState('');
    const [joiningDate, setJoiningDate] = useState(getCurrentDate());
    const [employeImage, setEmployeImage] = useState(null);
    const [employeImagePreview, setEmployeImagePreview] = useState(null);
    const [employeImageMssg, setEmployeImageMssg] = useState('');
    const [name, setName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [gender, setGender] = useState('');
    const genderOptions = ['Male', 'Female'];
    const [dOB, setDOB] = useState('');
    const [pAddress, setPAddress] = useState('');
    const [sClass, setSClass] = useState('');
    const [selectedClassId, setSelectedClassID] = useState('');
    const [classOptons, setClassOptions] = useState([]);
    const [stateName, setStateName] = useState('');
    const [stateNameId, setStateNameId] = useState('');
    const [stateNameOptions, setStateNameOptions] = useState([]);
    const [cityName, setCityName] = useState('');
    const [cityNameId, setCityNameId] = useState('');
    const [cityNameOptions, setCityNameOptions] = useState([]);
    const [pinCode, setPinCode] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState(''); 
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const navigate = useNavigate();
    const { sID, setSID, userLoginType, setUserLoginType} = useSession();

    useEffect(() => {
        var userLoginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var schoolId = document.cookie.replace(/(?:(?:^|.*;\s*)sID\s*=\s*([^;]*).*$)|^.*$/, "$1");
  
        if (schoolId) {
          setUserLoginType(userLoginType);
          const parsedKey = parseInt(schoolId, 10);
          setSID(parsedKey);
        }
    }, [setSID, setUserLoginType]);

    const decryptSTRID = (encryptedId, secretKey) => {
      const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
      const bytes = CryptoJS.AES.decrypt(base64, secretKey);
      const originalId = bytes.toString(CryptoJS.enc.Utf8);
      return originalId;
    };

    useEffect(() => {
      if (encrypteSTRID) {
          const decryptedId = decryptSTRID(encrypteSTRID, secretKey);
          setSTRID(decryptedId);
      } else {
        setSTRID('');
      }
    }, [encrypteSTRID]);
  
    const handleSRNoChange = (e) => {
      const value = e.target.value
      setSRNo(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        SRNo: value ? '' : 'Please Enter Student SRNO'
      }))

    };

    const handleJoiningDateChange = (e) => {
      setJoiningDate(e.target.value)
    };

    const handleEmployeImageUploadChange = (e) => {
      const selectedImage = e.target.files[0];
      if (selectedImage) {
        setEmployeImage(selectedImage);
    
        const reader = new FileReader();
        reader.onload = (event) => {
          setEmployeImagePreview(event.target.result);
        };
        reader.readAsDataURL(selectedImage);
    
        if (selectedImage.size > 200 * 1024) {
          setEmployeImageMssg('Image size exceeds 200KB limit.');
          return;
        } else {
          setEmployeImageMssg('');
        }
      } else {
        setEmployeImage(null);
        setEmployeImagePreview(null);
        setEmployeImageMssg(''); 
      }
    };

    const handleNameChange = (e) => {
      const selectedValue = e.target.value
      setName(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: selectedValue ? '' : 'Please Enter Student Name'
      }));
    };

    const handleFatherNameChange = (e) => {
      const selectedValue = e.target.value
      setFatherName(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherName: selectedValue ? '' : "Please Enter Student Father's Name"
      }));
    };

    const handleGenderChange = (e) => {
      const selectedValue = e.target.value
      setGender(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: selectedValue ? '' : "Gender is required"
      }));
    };

    const handleDOBChange = (e) => {
      const selectedValue = e.target.value
      setDOB(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        dOB: selectedValue ? '' : "Date Of Birth is required"
      }));
    };

    const handlePAddressChange = (e) => {
      const selectedValue = e.target.value
      setPAddress(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        pAddress: selectedValue ? '' : "Please Enter Student Permanent Address"
      }));
    };

    const handleSClassChange = (e) => {
      const selectedValue = e.target.value
      setSClass(selectedValue)
      const selectedClasses = classOptons.find(option => option.name === selectedValue);
      if (selectedClasses) {
          setSelectedClassID(selectedClasses.id)
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedClassId: selectedValue ? '' : 'Error'
      }))
    };

    const handleStateNameChange = (e) => {
      const value = e.target.value
      setStateName(value);
      const selctedState = stateNameOptions.find(option => option.name === value)
      if(selctedState){
          setStateNameId(selctedState.id)
      }
      setErrors((prevErrors) => ({
          ...prevErrors,
          stateNameId: value ? '' : 'Please Enter State Name'
      }));
    }

    const handleCityNameChange = (e) => {
      const value = e.target.value
      setCityName(value);
      const selctedCity = cityNameOptions.find(option => option.name === value)
      if (selctedCity) {
          setCityNameId(selctedCity.id)
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        cityNameId: value ? '' : ''
      }))
    }

    const handlePinCodeChange = (e) => {
      const selectedValue = e.target.value
      setPinCode(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        pinCode: selectedValue ? '' : "Please Enter Student PIN Code"
      }));
    };

    const handleMobileNumberChange = (e) => {
      const selectedValue = e.target.value
      setMobileNumber(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: selectedValue ? '' : "Please Enter Student Mobile Number"
      }));
    };

    const handleWhatsappNumberChange = (e) => {
      const selectedValue = e.target.value
      setWhatsappNumber(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: selectedValue ? '' : "Please Enter Student Whatsapp Number"
      }));
    };

    const handleEmailChange = (e) => {
      const selectedValue = e.target.value
      setEmail(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: selectedValue ? '' : "Please Enter Student Email"
      }));
    };

    const handleUsernameChange = (e) => {
      const selectedValue = e.target.value
      setUsername(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: selectedValue ? '' : "Please Enter Username"
      }));
    };

    const handlePasswordChange = (e) => {
      const selectedValue = e.target.value
      setPassword(selectedValue)
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: selectedValue ? '' : "Please Enter Password"
      }));
    };

    const generatePassword = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&()';
      const passwordLength = 8;
      let newPassword = '';
      for (let i = 0; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        newPassword += characters[randomIndex];
      }
      setPassword(newPassword);
      setErrors((prevErrors) => ({
        ...prevErrors,
        password : newPassword ? '' : ''
      })); 
    };

    const handleIsSameAsMobileChange = (e) => {      
      if (e.target.checked) {
        setWhatsappNumber(mobileNumber);
      } else {
        setWhatsappNumber('');
      }
    };

    const handleIsSameAsEmailChange = (e) => {      
      if (e.target.checked) {
        setUsername(email);
      } else {
        setUsername('');
      }
    };

    const handleUploadClick = () => {
      document.getElementById('EmployeImageUpload').click();
    };
  
    const handleReset = () => {
      setEmployeImage(null);
      setEmployeImagePreview('');
      setEmployeImageMssg('');
      const fileInput = document.querySelector('input[name="EmployeImageUpload"]');
        if (fileInput) {
            fileInput.value = '';
        }
      setSRNo('');
      setName('');
      setFatherName('');
      setGender('');
      setDOB('');
      setPAddress('');
      setSClass('');
      setSelectedClassID('');
      setStateName('');
      setStateNameId('');
      setCityName('');
      setCityNameId('');
      setPinCode('');      
      setMobileNumber('');
      setWhatsappNumber('');
      setEmail('');
      setUsername('');
      setPassword('');
    };

    useEffect(() => {
      const fetchClass = async () => {
        try {
            const response = await fetch(`/api/getClasses`);
            if (response.ok) {
                const data = await response.json();
                const filteredOptions = data.recordset.filter(option => option.Class !== null).map(option => ({
                    name: option.Class,
                    id: option.CLID,
                    min: option.MinAge,
                    max: option.MaxAge
                }));
                setClassOptions(filteredOptions);
            } else {
                console.error('Error fetching options');
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
      };

      const fetchState = async () => {
          try {
              const response = await fetch(`/api/getStates`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                  },
              });

              if (response.ok) {
                  const data = await response.json();
                  const filteredOptions = data.recordset.filter(option => option.StateName !== null).map(option => ({
                      name: option.StateName,
                      id: option.STID,
                  }));
                  setStateNameOptions(filteredOptions);
              }
          } catch (error) {
              console.error('Internal Server Error');
          }
      };

      const fetchCity = async () => {
          try {
              const response = await fetch(`/api/getCityBuState/${stateNameId}`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                  },
              });

              if (response.ok) {
                  const data = await response.json();
                  const filteredOptions = data.recordset.filter(option => option.DistName !== null).map(option => ({
                      name: option.DistName,
                      id: option.DTID,
                  }));
                  setCityNameOptions(filteredOptions);
              }
          } catch (error) {
              console.error('Internal Server Error');
          }
      };

      fetchState();
      if (stateNameId) {
          fetchCity();
      }
      fetchClass();
    }, [stateNameId]);

    useEffect(() => {
      if (STRID) {
        fetch(`/api/getStudentsForUpdation/${STRID}`)
          .then((response) => response.json())
          .then((result) => {
            if (result && result.length > 0) {
              const clientdata = result[0];  
              setSRNo(clientdata.SRNo || ''); 
              setJoiningDate(clientdata.RegDate.slice(0, 10) || ''); 
              setEmployeImage(clientdata.STPhoto || ''); 
              setName(clientdata.StudentName || ''); 
              setFatherName(clientdata.FName || ''); 
              setGender(clientdata.Gender || ''); 
              setDOB(clientdata.DOB.slice(0, 10) || ''); 
              setPAddress(clientdata.PAdd || ''); 
              setSClass(clientdata.Class || ''); 
              setSelectedClassID(clientdata.CLID || ''); 
              setStateName(clientdata.StateName || ''); 
              setStateNameId(clientdata.STID || ''); 
              setCityName(clientdata.DistName || ''); 
              setCityNameId(clientdata.DTID || ''); 
              setPinCode(clientdata.PinCode || ''); 
              setMobileNumber(clientdata.Mobile || ''); 
              setWhatsappNumber(clientdata.Whatsapp || ''); 
              setEmail(clientdata.Email || '');
              setUsername(clientdata.Username || '');
              setPassword(clientdata.Password || '');
            } 
          })
          .catch((error) => {
          });
      } 
    }, [STRID]);

    const handleSubmit = async (e) => {
        setLoading(true);     
        if (isSubmitting) {
          return; 
        }

        if (!SRNo || !name || !fatherName || !gender || !dOB || !pAddress || !selectedClassId || !stateNameId || !cityNameId || !pinCode || !mobileNumber || !email || !username || !password || !employeImage) {
          setErrors((prevErrors) => ({
              ...prevErrors,
              SRNo: !SRNo ? 'SRNO is required' : prevErrors.SRNo,
              name: !name ? 'Name is required' : prevErrors.name,
              fatherName: !fatherName ? "Father's/Husband's Name is required" : prevErrors.fatherName,
              gender: !gender ? 'Gender is required' : prevErrors.gender,
              dOB: !dOB ? 'Date Of birth is required' : prevErrors.dOB,
              pAddress: !pAddress ? 'Address is required' : prevErrors.pAddress,
              selectedClassId: !selectedClassId ? 'Class is required' : prevErrors.selectedClassId,
              stateNameId: !stateNameId ? 'State is required' : prevErrors.stateNameId,
              cityNameId: !cityNameId ? 'City is required' : prevErrors.cityNameId,
              pinCode: !pinCode ? 'Pin Code is required' : prevErrors.pinCode,
              mobileNumber: !mobileNumber ? "Mobile Number is required" : prevErrors.mobileNumber,
              email: !email ? "Email is required" : prevErrors.email,
              username: !username ? "Username is required" : prevErrors.username,
              password: !password ? "Password is required" : prevErrors.password,
          }));

          if(!employeImage){
            setEmployeImageMssg('Student Image Is Required')
          }
          
          setLoading(false);
          setIsSubmitting(false);
          return;
        }      

        setIsSubmitting(true);   
        const formData = new FormData();
        formData.append('sID', sID);
        formData.append('SRNo', SRNo);
        formData.append('joiningDate', joiningDate);
        formData.append('EmployeImage', employeImage);
        formData.append('name', name);
        formData.append('fatherName', fatherName);
        formData.append('gender', gender);
        formData.append('dOB', dOB);
        formData.append('pAddress', pAddress);
        formData.append('selectedClassId', selectedClassId);
        formData.append('stateNameId', stateNameId);
        formData.append('cityNameId', cityNameId);
        formData.append('pinCode', pinCode);
        formData.append('mobileNumber', mobileNumber);
        formData.append('whatsappNumber', whatsappNumber);
        formData.append('email', email);
        formData.append('username', username);
        formData.append('password', password);
        formData.append('userLoginType', userLoginType);
        if(STRID){
            try {
                const response = await fetch(`/api/UpdateRegisteredStudents/${STRID}`, {
                  method: 'PUT',
                  body: formData,
                });
                if (response.ok) {
                  const data = await response.json();
                  setShowModal(true);
                  setFailureMessage('');
                  setSuccessMessage(data.message);
                  handleReset();
                } else {
                  const data = await response.json();
                  setShowModal(true);
                  setSuccessMessage('')
                  setFailureMessage(data.error);
                }
                } catch (error) {
                  console.error('Error uploading', error);
                } finally {
                  setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/RegisterStudents`, {
                  method: 'POST',
                  body: formData,
                });

                if (response.ok) {
                  const data = await response.json();
                  setShowModal(true);
                  setFailureMessage('');
                  setSuccessMessage(data.message);
                  handleReset();
                  navigate(`/ViewRegisterStudents?Type=ViewRegisterStudents`)
                } else {
                  const data = await response.json();
                  setShowModal(true);
                  setSuccessMessage('')
                  setFailureMessage(data.error);
                }
                } catch (error) {
                  console.error('Error uploading', error);
                } finally {
                  setLoading(false);
            }
        }

        setIsSubmitting(false);
    }

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);
    
    const generateBreadcrumbs = (currentRoute) => {
      const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

      const routeParts = currentRoute.split('/').filter(Boolean);
      let urlSoFar = '';
      routeParts.forEach((part, index) => {
        const isNumeric = /^\d+$/.test(part);
        const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
        const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);
    
        if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
          urlSoFar += `/${part}`;
          breadcrumbsArray.push({ label: part, url: urlSoFar });
        }
      });

      return breadcrumbsArray;
    };

  return (
    <>
        {showModal && (
          <div className="modal-backdrop show"></div>
        )}
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
                <h5 className="modal-title">Message</h5>
                <span onClick={()=> setShowModal(false)}><i className="bi bi-x-lg fs-6"></i></span>
              </div>
              <div className="modal-body">
                {successMessage && <span className='text-success'>{successMessage}</span>}
                {failureMessage && <span className='text-danger'>{failureMessage}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className='my-2 mx-2 mobile-margin'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                      {index === breadcrumbs.length - 1 ? (
                        breadcrumb.label
                      ) : (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      )}
                    </li>
                  ))}
                </ol>
            </nav>
            <div className='shadow-sm p-3 mb-3 bg-white rounded'>
                <div className='row d-flex align-items-center'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                  <TextField label="SRNo" type='number' variant="standard" fullWidth value={SRNo} onChange={handleSRNoChange} helperText={errors.SRNo} error={!!errors.SRNo}/>
                                </div>
                                <div className='col-6 mb-2'>
                                  <TextField label="Date Of Registration" type='date' variant="standard" fullWidth value={joiningDate} onChange={handleJoiningDateChange} helperText={errors.joiningDate} error={!!errors.joiningDate} InputLabelProps={{shrink: true}}/>
                                </div>
                                <div className='col-12 mb-2'>
                                  <TextField label="Name" variant="standard" fullWidth value={name} onChange={handleNameChange} helperText={errors.name} error={!!errors.name}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='float-end infoProfile-img-container' onClick={handleUploadClick}>
                                {STRID && <img src={employeImagePreview ? employeImagePreview : ( employeImage ? `/api/Students/${employeImage}` : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQadzeS41vONIfTEWPgkhrALgZ31RSwdba_AA&s`)} alt="studentImage" style={{maxHeight: '100px', width: '100%', height: 'auto', objectFit:'contain', border: '2px solid black'}}  />}
                               {!STRID &&<img src={employeImagePreview ? employeImagePreview : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQadzeS41vONIfTEWPgkhrALgZ31RSwdba_AA&s`} alt="studentImage" style={{maxHeight: '100px', width: '100%', height: 'auto', objectFit:'contain', border: '2px solid black'}}  />}
                                {employeImageMssg && <p className="text-danger">{employeImageMssg}</p>}
                            </div>
                            <input className='d-none' id="EmployeImageUpload" type="file" name="EmployeImage" accept="image/*" value={null} onChange={handleEmployeImageUploadChange}  />
                        </div>
                    </div>
                    <div className='col-md-4 mb-2'>
                        <TextField label="Father's Name" variant="standard" fullWidth value={fatherName} onChange={handleFatherNameChange} helperText={errors.fatherName} error={!!errors.fatherName}/>
                    </div>
                    <div className='col-6 col-md-4 mb-2'>
                        <FormControl variant="standard" fullWidth error={!!errors.gender}>
                          <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                          <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={gender} label="Department" onChange={handleGenderChange}>
                            {genderOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{errors.gender}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-6 col-md-4 mb-2'>
                      <TextField label="DOB" type='date' variant="standard" fullWidth value={dOB} onChange={handleDOBChange} helperText={errors.dOB} error={!!errors.dOB} InputLabelProps={{shrink: true}}/>
                    </div>
                    <div className='col-12 mb-2'>
                      <TextField label="Permanent Address" variant="standard" fullWidth multiline rows={2} value={pAddress} onChange={handlePAddressChange} helperText={errors.pAddress} error={!!errors.pAddress}/>
                    </div>
                    <div className='col-4 col-md-3 mb-2'>
                        <FormControl variant="standard" fullWidth error={!!errors.selectedClassId}>
                            <InputLabel id="demo-simple-select-standard-label">Select Class</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sClass} onChange={handleSClassChange}>
                                {classOptons.map((option, index) => (
                                    <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.selectedClassId}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-4 col-md-3 mb-2'>
                        <FormControl variant="standard" fullWidth error={!!errors.stateNameId}>
                          <InputLabel id="demo-simple-select-standard-label">Select State</InputLabel>
                          <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={stateName} onChange={handleStateNameChange}>
                            {stateNameOptions.map((option, index) => (
                                <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{errors.stateNameId}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-4 col-md-3 mb-2'>
                        <FormControl variant="standard" fullWidth error={!!errors.cityNameId}>
                            <InputLabel id="demo-simple-select-standard-label">Select City</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cityName} onChange={handleCityNameChange}>
                                {cityNameOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.cityNameId}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-4 col-md-3 mb-2'>
                        <TextField label="Pin Code" type='number' variant="standard" fullWidth value={pinCode} onChange={handlePinCodeChange} helperText={errors.pinCode} error={!!errors.pinCode}/>
                    </div>
                    <div className='col-8 col-md-4 mb-2'>
                        <TextField label="Mobile Number" type='number' variant="standard" fullWidth value={mobileNumber} onChange={handleMobileNumberChange} helperText={errors.mobileNumber} error={!!errors.mobileNumber} InputProps={{endAdornment: (<InputAdornment position="end"> <FormControlLabel control={<Checkbox onChange={handleIsSameAsMobileChange} />} label="Whatsapp?" /></InputAdornment>),}}/>
                    </div>
                    <div className='col-4 col-md-4 mb-2'>
                        <TextField label="Whatsapp Number" type='number' variant="standard" fullWidth value={whatsappNumber} onChange={handleWhatsappNumberChange} helperText={errors.whatsappNumber} error={!!errors.whatsappNumber}/>
                    </div>
                    <div className='col-8 col-md-4 mb-2'>
                        <TextField label="Email" variant="standard" fullWidth value={email} onChange={handleEmailChange} helperText={errors.email} error={!!errors.email} InputProps={{endAdornment: (<InputAdornment position="end"> <FormControlLabel control={<Checkbox onChange={handleIsSameAsEmailChange} />} label="Username?" /></InputAdornment>),}}/>
                    </div>
                    <div className='col-4 mb-2'>
                        <TextField label="Username" variant="standard" fullWidth value={username} onChange={handleUsernameChange} helperText={errors.username} error={!!errors.username}/>
                    </div>
                    <div className='col-4 mb-2'>
                        <TextField label="Password" variant="standard" fullWidth value={password} onChange={handlePasswordChange} helperText={errors.password} error={!!errors.password}/>
                    </div>
                    <div className='col-4 mb-2'>
                        <Button variant="contained" color="secondary" onClick={generatePassword}>Generate</Button>
                    </div>
              </div>
            </div>
            <div className='d-flex flex-row-reverse gap-3'>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={employeImageMssg}>{STRID ? 'Update' : 'Save'}</Button>
                <Button variant="contained" color="warning" onClick={handleReset}>Reset</Button>
            </div>
        </div>
        {loading && (
          <div className="modal-backdrop show"></div>
        )}
        <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
            <div className="modal-content shadow-lg bg-white rounded modal-m-sm">
              <div className="modal-body text-center my-2 mx-2">
               <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

