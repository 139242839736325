import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [userLoginType, setUserLoginType] = useState(null);
  const [privacyKey, setPrivacyKey] = useState(null);
  const [oId, setOId] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [logo, setLogo] = useState(null);
  const [sID, setSID] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [sTRID, setSTRID] = useState(null);
  const [sTPhoto, setSTPhoto] = useState(null);
  const [studentName, setStudentName] = useState(null);
  
  return (
    <SessionContext.Provider value={{ userLoginType, setUserLoginType, privacyKey, setPrivacyKey, oId, setOId, orgName, setOrgName, logo, setLogo, sID, setSID, schoolName, setSchoolName, schoolLogo, setSchoolLogo, sTRID, setSTRID, sTPhoto, setSTPhoto, studentName, setStudentName }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
