import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import { Link, useLocation } from "react-router-dom";

export default function StudentProfile() {
    const [data, setdata] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }]);
    const location = useLocation();
    const { sTRID, setSTRID, sTPhoto, setSTPhoto, studentName, setStudentName } = useSession();

    useEffect(() => {
        var sTRID = document.cookie.replace(/(?:(?:^|.*;\s*)sTRID\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
        var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (sTRID) {
            setSTRID(sTRID);
            setSTPhoto(sTPhoto);
            setStudentName(studentName);
        }
    }, [setSTRID, setSTPhoto, setStudentName]);

    useEffect(() => {
        if (sTRID) {
            fetch(`/api/getStudentInformation/${sTRID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const data = result[0];
                        setdata(data);
                    } else {
                        console.log('No data received');
                    }
                })
                .catch((error) => {
                });
        }
    }, [sTRID]);

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part) => {
            urlSoFar += `/${part}`;
            breadcrumbsArray.push({ label: part, url: urlSoFar });
        });

        return breadcrumbsArray;
    };

    return (
        <>
            <div className='mobile-margin'></div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {breadcrumbs.map((breadcrumb, index) => (
                        <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                            {index === breadcrumbs.length - 1 ? (
                                breadcrumb.label
                            ) : (
                                <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className='row mx-2'>
                <div className='col-md-4 print-col-4 shadow-sm p-3 mb-1 bg-body-tertiary rounded'>
                    <div className='d-flex mb-2'>
                        <img className='border border-primary' src={`/api/Students/${sTPhoto}`} alt='img' width='100px' height='auto' />
                        <div className='ms-3 mt-3'>
                            <h3>{studentName || 'Student Name'} </h3>
                            <p className='mb-1'>Father's Name.: <strong>{data.FName || ''}</strong></p>
                            <p>SRNo.: <strong>{data.SRNo || ''}</strong></p>
                        </div>
                    </div>
                    <div className='shadow-sm p-3 mb-1 bg-white rounded'>
                        <p className='d-flex justify-content-between'><strong>Class</strong><strong>{data.Class || ''}</strong></p>
                        <p className='d-flex justify-content-between'><strong>Gender</strong><strong>{data.Gender || ''}</strong></p>
                        <p className='d-flex justify-content-between'><strong>Username</strong><strong>{data.Username || ''}</strong></p>
                    </div>
                </div>
                <div className='col-md-8 print-col-8 shadow-sm p-0 mb-1 bg-white rounded'>
                    <nav className='mt-2 mx-2'>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/StudentProfile?Type=StudentProfile`}>Profile</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className='row mx-3 my-3 shadow-sm p-1 mb-1 bg-white rounded'>
                        <div className='col-6'>
                            <p>Registration Date</p>
                            <p>Date Of Birth</p>
                            <p>Mobile No</p>
                            <p>WhatsApp No</p>
                            <p>Email</p>
                        </div>
                        <div className='col-6'>
                            <p className='d-flex justify-content-between align-items-center'><span>{(data.RegDate && data.RegDate.slice(0, 10)) || 'Not Given'}</span> <span className='badge badge-danger-lighten mx-2' onClick={handlePrint}><i className='bi bi-printer fs-6'></i></span></p>
                            <p>{(data.DOB && data.DOB.slice(0, 10)) || 'Not Given'}</p>
                            <p>{data.Mobile || 'Not Given'}</p>
                            <p>{data.Whatsapp || 'Not Given'}</p>
                            <p>{data.Email || 'Not Given'}</p>
                        </div>
                    </div>
                    <div className='row mx-3 shadow-sm p-1 mb-1 bg-white rounded'>
                        <div className='col-12 bg-body-tertiary'><h5>Address Details</h5></div>
                        <div className='col-6'>
                            <p>State</p>
                            <p>City</p>
                            <p>Pin Code</p>
                            <p>Permanent Address</p>
                        </div>
                        <div className='col-6'>
                            <p>{data.StateName || 'Not Given'}</p>
                            <p>{data.DistName || 'Not Given'}</p>
                            <p>{data.PinCode || 'Not Given'}</p>
                            <p>{data.PAdd || 'Not Given'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

