import { TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import examAnimation from '../Assets/Animation/Animation - 1733833218652.json'; 
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useSession } from './SessionContext';
import Lottie from 'lottie-react'; 
import CryptoJS from 'crypto-js';
const secretKey = 'allloverthebridge415998Hu08Da270901';

export default function CreateOnlineExam() {
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = `${now.getMonth() + 1}`.padStart(2, '0');
        const day = `${now.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const getCurrentTime = () => {
        const now = new Date();
        const hours = `${now.getHours()}`.padStart(2, '0');
        const minutes = `${now.getMinutes()}`.padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const { encrypteEXID } = useParams();
    const [EXID, setEXID] = useState('');
    const [examTitle, setExamTitle] = useState('');
    const [description, setDescription] = useState('');
    const [set, setSet] = useState('');
    const [selectedSetId, setSelectedSetId] = useState('');
    const [setOptions, setSetOptions] = useState([]);
    const [instruction, setInstruction] = useState('');
    const [selectedInstructionId, setSelectedInstructionId] = useState('');
    const [instructionOptions, setInstructionOptions] = useState([]);
    const [Class, setClass] = useState('');
    const [subject, setSubject] = useState('');
    const [passingMarks, setPassingMarks] = useState('');
    const [startExamDate, setStartExamDate] = useState(getCurrentDate());
    const [startExamTime, setStartExamTime] = useState(getCurrentTime());
    const [endExamDate, setEndExamDate] = useState(getCurrentDate());
    const [endExamTime, setEndExamTime] = useState(getCurrentTime());
    const [duration, setDuration] = useState('');
    const [SRAE, setSRAE] = useState('');
    const [SAAE, setSAAE] = useState('');
    const [payStatus, setPayStatus] = useState('');
    const payStatusOptions = ['Paid', 'Free'];
    const [published, setPublished] = useState('');
    const Options = ['Yes', 'No'];
    const [errors, setErrors] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Online Exam', url: '/ViewOnlineExam?Type=OnlineExam' }]);
    const location = useLocation();
    const navigate = useNavigate();
    const { userLoginType, setUserLoginType } = useSession();

    useEffect(() => {
        var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

        if (loginType) {
            setUserLoginType(loginType);
        }
    }, [setUserLoginType]);

    const decryptId = (encryptedId, secretKey) => {
        const base64 = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(base64, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    };

    useEffect(() => {
        if (encrypteEXID) {
            const decryptedId = decryptId(encrypteEXID, secretKey);
            setEXID(decryptedId);
        }
    }, [encrypteEXID]);

    const handleExamTitleChange = (e) => {
        const selectedValue = e.target.value
        setExamTitle(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            examTitle: selectedValue ? '' : 'Please Enter Exam Title'
        }));
    };

    const handleDescriptionChange = (e) => {
        const selectedValue = e.target.value
        setDescription(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: selectedValue ? '' : 'Please Enter Description'
        }));
    };

    const handleSetChange = (e) => {
        const selectedValue = e.target.value
        setSet(selectedValue)
        const selectedSet = setOptions.find(option => option.name === selectedValue);
        if (selectedSet) {
            setSelectedSetId(selectedSet.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedSetId: selectedValue ? '' : ''
        }));
    };

    const handleInstructonChange = (e) => {
        const selectedValue = e.target.value
        setInstruction(selectedValue)
        const selectedInstructon = instructionOptions.find(option => option.name === selectedValue);
        if (selectedInstructon) {
            setSelectedInstructionId(selectedInstructon.id)
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedInstructionId: selectedValue ? '' : ''
        }));
    };

    const handlePassingMarksChange = (e) => {
        const selectedValue = e.target.value
        setPassingMarks(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            passingMarks: selectedValue ? '' : 'Please Enter Passing Marks'
        }));
    };

    const handleStartExamDateChange = (e) => {
        const selectedValue = e.target.value
        setStartExamDate(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            startExamDate: selectedValue ? '' : 'Please Enter Exam Start Date'
        }));
    };

    const handleStartExamTimeChange = (e) => {
        const selectedValue = e.target.value
        setStartExamTime(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            startExamTime: selectedValue ? '' : 'Please Enter Exam Start Time'
        }));
    };

    const handleEndExamDateChange = (e) => {
        const selectedValue = e.target.value
        setEndExamDate(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            endExamDate: selectedValue ? '' : 'Please Enter End Exam Date'
        }));
    };

    const handleEndExamTimeChange = (e) => {
        const selectedValue = e.target.value
        setEndExamTime(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            endExamTime: selectedValue ? '' : 'Please Enter End Exam Time'
        }));
    };

    const handleDurationChange = (e) => {
        const selectedValue = e.target.value
        setDuration(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            duration: selectedValue ? '' : 'Please Enter Exam Duratiomn'
        }));
    };

    const handleSRAEChange = (e) => {
        const selectedValue = e.target.value
        setSRAE(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            SRAE: selectedValue ? '' : 'This Field Is Required'
        }));
    };

    const handleSAAEChange = (e) => {
        const selectedValue = e.target.value
        setSAAE(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            SAAE: selectedValue ? '' : 'This Field Is Required'
        }));
    };

    const handlePayStatusChange = (e) => {
        const selectedValue = e.target.value
        setPayStatus(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            payStatus: selectedValue ? '' : 'This Field Is Required'
        }));
    };

    const handlePublishedChange = (e) => {
        const selectedValue = e.target.value
        setPublished(selectedValue)
        setErrors((prevErrors) => ({
            ...prevErrors,
            published: selectedValue ? '' : 'This Field Is Required'
        }));
    };

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setFailureMessage('');
        setShowModal(false);
    };

    const handleReset = () => {
        setExamTitle('');
        setDescription('');
        setSet('');
        setSelectedSetId('');
        setInstruction('');
        setSelectedInstructionId('');
        setClass('');
        setSubject('');
        setPassingMarks('');
        setDuration('');
        setSRAE('');
        setSAAE('');
        setPayStatus('');
        setPublished('');
    };

    useEffect(() => {
        const fetchSet = async () => {
            try {
                const response = await fetch(`/api/getSetName`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.SetName !== null).map(option => ({
                        name: option.SetName,
                        id: option.SEID,
                    }));
                    setSetOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchInstructions = async () => {
            try {
                const response = await fetch(`/api/getInstructions`);
                if (response.ok) {
                    const data = await response.json();
                    const filteredOptions = data.recordset.filter(option => option.Title !== null).map(option => ({
                        name: option.Title,
                        id: option.INID,
                    }));
                    setInstructionOptions(filteredOptions);
                } else {
                    console.error('Error fetching options');
                }
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchSet();
        fetchInstructions();
    }, []);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (isSubmitting) {
            return;
        }

        if (!examTitle || !description || !selectedSetId || !selectedInstructionId || !passingMarks || !startExamDate || !startExamTime || !endExamDate || !endExamTime || !duration || !SRAE || !SAAE || !payStatus || !published) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                examTitle: !examTitle ? 'Exam Title Is Required' : prevErrors.examTitle,
                description: !description ? 'Description Is Required' : prevErrors.description,
                selectedSetId: !selectedSetId ? 'Set Is Required' : prevErrors.selectedSetId,
                selectedInstructionId: !selectedInstructionId ? 'Instructon Is Required' : prevErrors.selectedInstructionId,
                passingMarks: !passingMarks ? 'Passing Marks Is Required' : prevErrors.passingMarks,
                startExamDate: !startExamDate ? 'Start Date Is Required' : prevErrors.startExamDate,
                startExamTime: !startExamTime ? 'Start Time Is Required' : prevErrors.startExamTime,
                endExamDate: !endExamDate ? 'End Date Is Required' : prevErrors.endExamDate,
                endExamTime: !endExamTime ? 'End Time Is Required' : prevErrors.endExamTime,
                duration: !duration ? 'Duration Is Required' : prevErrors.duration,
                SRAE: !SRAE ? 'This Field Is Required' : prevErrors.SRAE,
                SAAE: !SAAE ? 'This Field Is Required' : prevErrors.SAAE,
                payStatus: !payStatus ? 'Payemnt Status Is Required' : prevErrors.payStatus,
                published: !published ? 'Published Is Required' : prevErrors.published,
            }));

            setLoading(false);
            setIsSubmitting(false);
            return;
        }
        setIsSubmitting(true);

        if (EXID) {
            try {
                const response = await fetch(`/api/UpdateExam/${EXID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ examTitle, description, selectedSetId, selectedInstructionId, passingMarks, startExamDate, startExamTime, endExamDate, endExamTime, duration, SRAE, SAAE, payStatus, published, userLoginType }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    handleReset();
                    navigate(`/ViewOnlineExam?Type=OnlineExam`);
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                }
            } catch (error) {
                console.error('Error updating department:', error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const response = await fetch(`/api/CreateExam`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ examTitle, description, selectedSetId, selectedInstructionId, passingMarks, startExamDate, startExamTime, endExamDate, endExamTime, duration, SRAE, SAAE, payStatus, published, userLoginType }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setShowModal(true);
                    setFailureMessage('');
                    setSuccessMessage(data.message);
                    handleReset();
                    navigate(`/ViewOnlineExam?Type=OnlineExam`);
                } else {
                    const data = await response.json();
                    setShowModal(true);
                    setSuccessMessage('');
                    setFailureMessage(data.error);
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (selectedSetId) {
            fetch(`/api/getClassSubjectBySet/${selectedSetId}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setClass(clientdata.Class || '');
                        setSubject(clientdata.Subject || '');                       
                    }
                })
                .catch((error) => {
                });
        }
    }, [selectedSetId]);

    useEffect(() => {
        if (EXID) {
            fetch(`/api/getUpdateExam/${EXID}`)
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length > 0) {
                        const clientdata = result[0];
                        setExamTitle(clientdata.ExamTitle || '');
                        setDescription(clientdata.Description || '');
                        setSet(clientdata.SetName || '');
                        setSelectedSetId(clientdata.SEID || '');
                        setInstruction(clientdata.Title || '');
                        setSelectedInstructionId(clientdata.INID || '');
                        setPassingMarks(clientdata.PassNo || '');
                        setStartExamDate(clientdata.StartDate.slice(0, 10) || getCurrentDate());
                        setStartExamTime(clientdata.STime.slice(11, 16) || getCurrentTime());
                        setEndExamDate(clientdata.EndDate.slice(0, 10) || getCurrentDate());
                        setEndExamTime(clientdata.ETime.slice(11, 16) || getCurrentTime());
                        setDuration(clientdata.ExamDuration || '');
                        setSRAE(clientdata.SRAE || '');
                        setSAAE(clientdata.SAAE || '');
                        setPayStatus(clientdata.PayStatus || '');
                        setPublished(clientdata.Published || '');
                    }
                })
                .catch((error) => {
                });
        }
    }, [EXID]);

    useEffect(() => {
        const currentRoute = location.pathname;
        const newBreadcrumbs = generateBreadcrumbs(currentRoute);
        setBreadcrumbs(newBreadcrumbs);
    }, [location]);

    const generateBreadcrumbs = (currentRoute) => {
        const breadcrumbsArray = [{ label: 'Dashboard', url: '/Dashboard' }, { label: 'Online Exam', url: '/ViewOnlineExam?Type=OnlineExam' }];

        const routeParts = currentRoute.split('/').filter(Boolean);
        let urlSoFar = '';
        routeParts.forEach((part, index) => {
            const isNumeric = /^\d+$/.test(part);
            const isMongoDBObjectId = /^[a-f0-9]{24}$/.test(part);
            const isEncryptedId = /^[a-zA-Z0-9_-]{32,}$/.test(part);

            if (!isNumeric && !isMongoDBObjectId && !isEncryptedId) {
                urlSoFar += `/${part}`;
                breadcrumbsArray.push({ label: part, url: urlSoFar });
            }
        });

        return breadcrumbsArray;
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="modal-header">
                            <h5 className="modal-title">Success Message</h5>
                            <span onClick={removeSuccessMessage}><i className="bi bi-x-lg fs-6"></i></span>
                        </div>
                        <div className="modal-body">
                            {successMessage && <p className='text-success'>{successMessage}</p>}
                            {failureMessage && <p className='text-danger'>{failureMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-2 mobile-margin mx-2'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <li key={index} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
                                {index === breadcrumbs.length - 1 ? (
                                    breadcrumb.label
                                ) : (
                                    <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>

                <div className='row mx-1'>
                    <div className='col-md-6 mb-2'>
                        <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-12 mb-2'>
                                    <TextField label='Exam Title' variant="standard" type="text" fullWidth value={examTitle} onChange={handleExamTitleChange} helperText={errors.examTitle} error={!!errors.examTitle} />
                                </div>
                                <div className='col-12 mb-2'>
                                    <TextField label='Description' variant="standard" type="text" fullWidth value={description} onChange={handleDescriptionChange} helperText={errors.description} error={!!errors.description} />
                                </div>
                                <div className='col-6 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.selectedSetId}>
                                        <InputLabel id="demo-simple-select-standard-label">Set</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={set} onChange={handleSetChange}>
                                            {setOptions.map((option, index) => (
                                                <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.selectedSetId}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='col-6 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.selectedInstructionId}>
                                        <InputLabel id="demo-simple-select-standard-label">Instruction</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={instruction} onChange={handleInstructonChange}>
                                            {instructionOptions.map((option, index) => (
                                                <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.selectedInstructionId}</FormHelperText>
                                    </FormControl>
                                </div>
                                {selectedSetId &&
                                    <div className='col-6 mb-2'>
                                        <TextField label='Subject' variant="standard" type="text" fullWidth value={subject} />
                                    </div>
                                }
                                {selectedSetId &&
                                    <div className='col-6 mb-2'>
                                        <TextField label='Class' variant="standard" type="text" fullWidth value={Class} />
                                    </div>
                                }
                                <div className='col-12 mb-2'>
                                    <TextField label='Passing Marks' variant="standard" type="number" fullWidth value={passingMarks} onChange={handlePassingMarksChange} helperText={errors.passingMarks} error={!!errors.passingMarks} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 mb-2'>
                        <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-6 mb-2'>
                                    <TextField label='Start Exam Date' variant="standard" type="date" value={startExamDate} onChange={handleStartExamDateChange} helperText={errors.startExamDate} error={!!errors.startExamDate} fullWidth InputLabelProps={{ shrink: true }} />
                                </div>
                                <div className='col-6 mb-2'>
                                    <TextField label='Start Exam Time' variant="standard" type="time" value={startExamTime} onChange={handleStartExamTimeChange} helperText={errors.startExamTime} error={!!errors.startExamTime} fullWidth InputLabelProps={{ shrink: true }} />
                                </div>
                                <div className='col-6 mb-2'>
                                    <TextField label='End Exam Date' variant="standard" type="date" value={endExamDate} onChange={handleEndExamDateChange} helperText={errors.endExamDate} error={!!errors.endExamDate} fullWidth InputLabelProps={{ shrink: true }} />
                                </div>
                                <div className='col-6 mb-2'>
                                    <TextField label='End Exam Time' variant="standard" type="time" value={endExamTime} onChange={handleEndExamTimeChange} helperText={errors.endExamTime} error={!!errors.endExamTime} fullWidth InputLabelProps={{ shrink: true }} />
                                </div>
                                <div className='col-12 mb-2'>
                                    <TextField label='Exam Time Duration' variant="standard" type="number" value={duration} onChange={handleDurationChange} helperText={errors.duration} error={!!errors.duration} fullWidth />
                                </div>
                                <div className='col-6 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.SRAE}>
                                        <InputLabel id="demo-simple-select-standard-label">Show Result After Exam</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={SRAE} onChange={handleSRAEChange}>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.SRAE}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='col-6 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.SAAE}>
                                        <InputLabel id="demo-simple-select-standard-label">Show Answer After Exam </InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={SAAE} onChange={handleSAAEChange}>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.SAAE}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mb-2'>
                        <div className='shadow-sm p-2 mb-2 bg-white rounded'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-6 col-md-3 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.payStatus}>
                                        <InputLabel id="demo-simple-select-standard-label">Payment Status</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={payStatus} onChange={handlePayStatusChange}>
                                            {payStatusOptions.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.payStatus}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='col-6 col-md-3 mb-2'>
                                    <FormControl variant="standard" fullWidth error={!!errors.published}>
                                        <InputLabel id="demo-simple-select-standard-label">Published </InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={published} onChange={handlePublishedChange}>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.published}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='col-6 col-md-3 mb-2'>
                                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} >{EXID ? 'Update' : 'Save'}</Button>
                                </div>
                                <div className='col-6 col-md-3 mb-2'>
                                    <Button variant="contained" color="secondary" fullWidth onClick={handleReset}>Reset</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {loading && (
                <div className="modal-backdrop show"></div>
            )}
            <div className={`modal fade ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered text-center modal-sm" role="document">
                    <div className="modal-content shadow-lg bg-white rounded modal-m-sm justify-content-center align-items-center">
                        <Lottie animationData={examAnimation} loop={true} autoplay={true} style={{width: '200px', height: '200px',}} />
                    </div>
                </div>
            </div>
        </>
    )
}
