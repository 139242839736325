import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useSession } from './SessionContext';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import Face6TwoToneIcon from '@mui/icons-material/Face6TwoTone';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';
import PhonelinkTwoToneIcon from '@mui/icons-material/PhonelinkTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import SouthAmericaTwoToneIcon from '@mui/icons-material/SouthAmericaTwoTone';
import EditLocationTwoToneIcon from '@mui/icons-material/EditLocationTwoTone';
import GpsNotFixedTwoToneIcon from '@mui/icons-material/GpsNotFixedTwoTone';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import ClassTwoToneIcon from '@mui/icons-material/ClassTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import Filter9PlusTwoToneIcon from '@mui/icons-material/Filter9PlusTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';

export default function AdminNavbar() {
  const { logo, setLogo, orgName, setOrgName, schoolName, setSchoolName, schoolLogo, setSchoolLogo, sTPhoto, setSTPhoto, studentName, setStudentName, userLoginType, setUserLoginType } = useSession();

  useEffect(() => {
    var logo = document.cookie.replace(/(?:(?:^|.*;\s*)logo\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var organisation = document.cookie.replace(/(?:(?:^|.*;\s*)orgName\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var schoolName = document.cookie.replace(/(?:(?:^|.*;\s*)schoolName\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var schoolLogo = document.cookie.replace(/(?:(?:^|.*;\s*)schoolLogo\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var sTPhoto = document.cookie.replace(/(?:(?:^|.*;\s*)sTPhoto\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var studentName = document.cookie.replace(/(?:(?:^|.*;\s*)studentName\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var userLoginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (logo) {
      setLogo(logo);
      setOrgName(organisation);
      setSchoolName(schoolName);
      setSchoolLogo(schoolLogo);
      setSTPhoto(sTPhoto);
      setStudentName(studentName);
      setUserLoginType(userLoginType);
    }
  }, [setLogo, setOrgName, setSchoolName, setSchoolLogo, setSTPhoto, setStudentName, setUserLoginType]);

  const handleLogout = () => {
    document.cookie = "loginType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "oId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "logo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "orgName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "sID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "schoolLogo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "schoolName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "sTRID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "sTPhoto=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "studentName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    fetch(`/Logout`, {
      method: 'POST',
    })
      .then((response) => {
        if (response.status === 200) {
          window.localStorage.removeItem("isLoggedIn");
          window.location.href = "/";
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  const closeNavbar = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    if (navbarToggler) {
      navbarToggler.click();
    }
  };

  let inactivityTimeout;
  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(handleLogout, 20 * 60 * 1000);
  };

  useEffect(() => {
    resetInactivityTimeout();
    document.addEventListener('mousemove', resetInactivityTimeout);
    document.addEventListener('keydown', resetInactivityTimeout);

    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener('mousemove', resetInactivityTimeout);
      document.removeEventListener('keydown', resetInactivityTimeout);
    };
  });

  return (
    <>
      <style>
        {`           
            .logo {
              max-width: 100px; 
              max-height: 100px;
              width: auto;
              height: 50px;
              margin-right: 10px;
            }

        `}
      </style>
      <nav className='navbar navbar-light bg-white shadow-sm mb-1 fixed'>
        <div className="container-fluid">
          <h1 className="navbar-brand brand d-lg-none mb-0" style={{ color: 'black' }}><img src={`/api/logoUploads/${logo}`} alt="Logo" className="mb-0" style={{ maxWidth: '200px', maxHeight: '200px', width: 'auto', height: '50px' }} /></h1>
          <form className="d-flex mt-0">
            <button className="btn btn-primary d-none d-lg-block" type="button">Search</button>
          </form>
          <div className="dropdown mx-3 d-none d-lg-flex">
            <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0 p-2" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9" }} >
              <img src={schoolLogo ? `/api/logoUploads/${schoolLogo}` : "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png"} alt="" width="32" height="32" className="rounded-circle me-0" /><span style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{schoolName ? schoolName : orgName}</span>
            </p>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
              {studentName &&
                <>
                  <li><Link className="dropdown-item" to="/StudentProfile?Type=StudentProfile" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><img src={sTPhoto ? `/api/Students/${sTPhoto}` : "https://www.seekpng.com/png/detail/48-4484206_user-icon-png-clipart-user-profile-computer-icons.png"} alt="studentprofile" className="profile-image rounded-circle me-2" />{studentName}</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                </>
              }
              <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <LogoutRoundedIcon style={{ marginRight: '1rem' }} />Log out</Link></li>
            </ul>
          </div>
          <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title oldenglishtextmt" id="offcanvasNavbarLabel"><img src={`/api/logoUploads/${logo}`} alt="Logo" className="mb-0" style={{ maxWidth: '200px', maxHeight: '200px', width: 'auto', height: '50px' }} /></h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item" onClick={closeNavbar}>
                  <Link className="nav-link" aria-current="page" to="/Dashboard?Type=Dashboard"><DashboardCustomizeTwoToneIcon style={{ marginRight: '8px' }} />Dashboard</Link>
                </li>
                {userLoginType !== 'School' && userLoginType !== 'Student' &&
                  <>
                    <li className="nav-item dropdown" key="Exam">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><HistoryEduTwoToneIcon style={{ marginRight: '8px' }} />Exam</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewQuestionBank?Type=QuestionBank"><DynamicFormTwoToneIcon style={{ marginRight: '8px' }} />Question Bank</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewOnlineExam?Type=OnlineExam"><PhonelinkTwoToneIcon style={{ marginRight: '8px' }} />Online Exam</Link></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown" key="Result">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><WorkspacePremiumTwoToneIcon style={{ marginRight: '8px' }} />Result</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/StudentResultMaster?Type=StudentResultMaster"><Face6TwoToneIcon style={{ marginRight: '8px' }} />Student Result</Link></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown" key="Affiliated Schools">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><AccountBalanceTwoToneIcon style={{ marginRight: '8px' }} />Affiliated Schools</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateSchools?Type=CreateSchools"><SchoolTwoToneIcon style={{ marginRight: '8px' }} />Create School</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewSchools?Type=ViewSchools"><ViewListTwoToneIcon style={{ marginRight: '8px' }} />View School</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewSRStudents?Type=ViewSRStudents"><PeopleAltTwoToneIcon style={{ marginRight: '8px' }} />View Registered Students</Link></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown" key="Setting">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><TuneTwoToneIcon style={{ marginRight: '8px' }} />Setting</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateRegion?Type=CreateRegion"><SouthAmericaTwoToneIcon style={{ marginRight: '8px' }} />Create Region</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateState?Type=CreateState"><EditLocationTwoToneIcon style={{ marginRight: '8px' }} />Create State</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateDistrict?Type=CreateDistrict"><GpsNotFixedTwoToneIcon style={{ marginRight: '8px' }} />Create District</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateClasses?Type=CreateClasses"><ClassTwoToneIcon style={{ marginRight: '8px' }} />Create Class</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateSubject?Type=CreateSubject"><MenuBookTwoToneIcon style={{ marginRight: '8px' }} />Create Subject</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/DefineExamLevel?Type=DefineExamLevel"><DriveFileRenameOutlineTwoToneIcon style={{ marginRight: '8px' }} />Create Exam Level</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateExamSet?Type=CreateExamSet"><Filter9PlusTwoToneIcon style={{ marginRight: '8px' }} />Create Exam Set</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateInstructions?Type=CreateInstructions"><DescriptionTwoToneIcon style={{ marginRight: '8px' }} />Create Instructions</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/CreateOrganisation?Type=CreateOrganisation"><CorporateFareTwoToneIcon style={{ marginRight: '8px' }} />Create Organisation</Link></li>
                      </ul>
                    </li>
                  </>
                }
                {userLoginType === 'School' &&
                  <>
                    <li className="nav-item dropdown" key="Registration">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><AppRegistrationTwoToneIcon style={{ marginRight: '8px' }} />Registration</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/RegisterStudents?Type=RegisterStudents"><HowToRegTwoToneIcon style={{ marginRight: '8px' }} />Student Registration</Link></li>
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewRegisterStudents?Type=ViewRegisterStudents"><PeopleAltTwoToneIcon style={{ marginRight: '8px' }} />View Students</Link></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown" key="Result">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><WorkspacePremiumTwoToneIcon style={{ marginRight: '8px' }} />Result</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/StudentResultMaster?Type=StudentResultMaster"><Face6TwoToneIcon style={{ marginRight: '8px' }} />Student Result</Link></li>
                      </ul>
                    </li>
                  </>
                }
                {userLoginType === 'Student' &&
                  <>
                    <li className="nav-item dropdown" key="Online Exam">
                      <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><SchoolTwoToneIcon style={{ marginRight: '8px' }} />Online Exam</p>
                      <ul className="dropdown-menu">
                        <li onClick={closeNavbar}><Link className="dropdown-item" to="/ViewExams?Type=AttemptTest"><QuizTwoToneIcon style={{ marginRight: '8px' }} />Attempt Test</Link></li>
                      </ul>
                    </li>
                  </>
                }
                <li className="nav-item dropdown">
                  <p className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "1px solid #ddd", borderRadius: "8px", background: "#f9f9f9" }}>
                    <img src={schoolLogo ? `/api/logoUploads/${schoolLogo}` : "https://www.seekpng.com/png/detail/448-4484206_user-icon-png-clipart-user-profile-computer-icons.png"} alt="" width="32" height="32" className="rounded-circle me-0" /><span style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{schoolName ? schoolName : orgName}</span>
                  </p>
                  <ul className="dropdown-menu">
                    {studentName &&
                      <>
                        <li><Link className="dropdown-item" to="/StudentProfile?Type=StudentProfile" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><img src={sTPhoto ? `/api/Students/${sTPhoto}` : "https://www.seekpng.com/png/detail/48-4484206_user-icon-png-clipart-user-profile-computer-icons.png"} alt="studentprofile" className="profile-image rounded-circle me-2" />{studentName}</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                      </>
                    }
                    <li><Link className="dropdown-item" onClick={handleLogout}><LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

